import isPromise from 'is-promise';
import type { FunctionComponent, ReactNode, PropsWithChildren } from 'react';
import { useContext } from 'react';
import * as React from 'react';

import { ResolvePromise } from './use-promise';

type AnyObject = Record<string, unknown>;

type ContextValueFactory<ContextType, Props = AnyObject> = (props: Props) => Promise<ContextType> | ContextType;

export interface Provider<ContextType, Props = AnyObject>
  extends FunctionComponent<Props & { children: React.ReactNode }> {
  context?: React.Context<ContextType | null>;
  displayName: string;
}

export interface ProviderOptions<ContextType, Props> {
  name?: string;
  wrapper?: (props: Props) => JSX.Element;
  context?: React.Context<ContextType | null>;
}

export type ContextValueCreator<ContextType, Props = AnyObject> =
  | ContextValueFactory<ContextType, Props>
  | ContextType
  | Promise<ContextType>;

function isContextFactory<ContextType, Props>(value: unknown): value is ContextValueFactory<ContextType, Props> {
  return typeof value === 'function';
}

/**
 * Create a new provider that can be access throughout the application. If a promise is returned we need to wrap
 * it in another component so that when we throw the promise the component state isn't lost.
 */
export function createProvider<ContextType, Props = AnyObject>(
  getContextValue: ContextValueCreator<ContextType, Props>,
  providerOptions: ProviderOptions<ContextType, Props> = {},
): Provider<ContextType, Props> {
  const { name, context, wrapper: Wrapper } = providerOptions;
  const Context = context ?? React.createContext<ContextType | null>(null);
  // eslint-disable-next-line react/function-component-definition
  const Provider: Provider<ContextType, Props> = (props) => {
    const { children } = props;
    if (Wrapper) {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Wrapper {...props}>
          <InnerProvider contextProps={props} getContextValue={getContextValue} context={Context}>
            {children}
          </InnerProvider>
        </Wrapper>
      );
    }

    return (
      <InnerProvider contextProps={props} getContextValue={getContextValue} context={Context}>
        {children}
      </InnerProvider>
    );
  };

  Provider.displayName = name ?? 'Provider';
  Provider.context = Context;
  return Provider;
}

interface InnerProviderProps<ContextType, Props> {
  contextProps: PropsWithChildren<Props>;
  getContextValue: ContextValueCreator<ContextType, Props>;
  context: React.Context<ContextType | null>;
  children: ReactNode;
}

function InnerProvider<ContextType, Props = AnyObject>({
  contextProps,
  getContextValue,
  context: Context,
  children,
}: InnerProviderProps<ContextType, Props>): JSX.Element {
  const value = isContextFactory<ContextType, Props>(getContextValue) ? getContextValue(contextProps) : getContextValue;

  if (isPromise(value)) {
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <ResolvePromise promise={value}>
        {(resolvedValue) => <Context.Provider value={resolvedValue}>{children}</Context.Provider>}
      </ResolvePromise>
    );
  }

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

interface BasicComponent {
  children: React.ReactNode;
}

/**
 * Mock a provider and set a dummy value on the context.
 */
export function mockProvider<T, Props>(provider: Provider<T, Props>, value: T): FunctionComponent<BasicComponent> {
  if (!provider.context) {
    throw new Error(`Context value not set on provider: ${provider.displayName}`);
  }

  const { Provider } = provider.context;
  return function MockProvider(props: BasicComponent) {
    const { children } = props;
    return <Provider value={value}>{children}</Provider>;
  };
}

/**
 * Access the context value of a provider.
 */
export function useProvider<T, P>(provider: Provider<T, P>): T {
  const value = useNullableProvider(provider);

  if (!value) {
    throw new Error('Provider not added to the root of the application.');
  }

  return value;
}

/**
 * This should be used when the Provider may not be a parent of the Component using this hook
 */
export function useNullableProvider<T, P>(provider: Provider<T, P>): T | null {
  if (!provider.context) {
    throw new Error(`Context value not set on provider: ${provider.displayName}.`);
  }

  const value = useContext(provider.context);

  return value;
}
