/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react/jsx-props-no-spreading */
import type { ModalHeaderProps } from '@newfront-insurance/core-ui';
import { ModalHeader as BaseModalHeader } from '@newfront-insurance/core-ui';
import { useAnalytics } from '@newfront-insurance/data-layer-client';

import type { TrackConfig } from '../../lib/types';

interface TrackEventsConfig {
  trackOnClose?: TrackConfig;
}

export type AnalyticModalHeaderProps = ModalHeaderProps & TrackEventsConfig;

export function ModalHeader(props: AnalyticModalHeaderProps): JSX.Element {
  const { trackOnClose, onClose } = props;
  const analytics = useAnalytics();
  return (
    <BaseModalHeader
      {...props}
      onClose={() => {
        if (trackOnClose) {
          const { eventName, eventProperties } = trackOnClose;
          analytics.track({
            event: eventName,
            properties: typeof eventProperties === 'function' ? eventProperties() : eventProperties,
          });
        }
        onClose();
      }}
    />
  );
}
