import { useState, useEffect } from 'react';
import type React from 'react';

interface Props {
  fallback?: JSX.Element | null;
  children: React.ReactNode;
}

/**
 * Prevent rendering the children when it's rendered on the server. Optionally
 * render a fallback instead.
 */
export function SSRBoundary(props: Props): JSX.Element | null {
  const { fallback = null, children } = props;

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    if (fallback) {
      return fallback;
    }
    return null;
  }
  return children as JSX.Element;
}
