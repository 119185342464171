import * as dateFn from 'date-fns';
import times from 'lodash/times';

import { CalendarDate } from './calendar-date';

export type Calendar = CalendarDate[][];

const LAST_DAY_OF_WEEK_INDEX = 6;
const DAYS_IN_WEEK = 7;

interface Options {
  year: number;
  // This is the current visible month. This is NOT a zero-based index.
  month: number;
}

function isValidYear(year: number): boolean {
  return year >= 1000 && year <= 9999;
}

/**
 * This beast generates a multi-dimensional array of dates,
 * dayjs date objects to be exact.
 * It takes a date and generates
 */
export function createCalendar(options: Options): Calendar {
  const { month, year } = options;
  const today = CalendarDate.today();

  const firstDayOfMonth = CalendarDate.fromCalendarDate({
    day: 1,
    month,
    year: isValidYear(year) ? year : today.getYear(),
  });

  const daysInMonth = dateFn.getDaysInMonth(month);
  const rows = [[]] as CalendarDate[][];
  let rowIndex = 0;

  // fill in initial rows
  times(daysInMonth, (num) => {
    const nextDay = firstDayOfMonth.addDays(num);
    const dow = nextDay.getDayOfWeek();
    rows[rowIndex].push(nextDay);
    if (dow === LAST_DAY_OF_WEEK_INDEX && num !== daysInMonth - 1) {
      rowIndex += 1;
      rows.push([]);
    }
  });

  const rowLen = rows.length;
  const firstRow = rows[0];

  // backfill days from prev month in first week of month
  if (firstRow.length !== DAYS_IN_WEEK) {
    const backfillBy = DAYS_IN_WEEK - firstRow.length;
    const first = firstRow[0];
    const startOfWeek = first.startOfWeek();
    times(backfillBy, (num) => {
      firstRow.splice(num, 0, startOfWeek.addDays(num));
    });
  }

  // frontfill days from next month in last week of month
  const lastRow = rows[rowLen - 1];
  if (lastRow.length !== DAYS_IN_WEEK) {
    const fillBy = DAYS_IN_WEEK - lastRow.length;
    const lastLen = lastRow.length;
    const last = lastRow[lastLen - 1];
    times(fillBy, (num) => {
      lastRow.push(last.addDays(num + 1));
    });
  }

  return rows;
}
