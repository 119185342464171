/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react/jsx-props-no-spreading */
import type { IconButtonProps } from '@newfront-insurance/core-ui';
import { IconButton as BaseIconButton } from '@newfront-insurance/core-ui';
import { useAnalytics } from '@newfront-insurance/data-layer-client';

import type { TrackConfig } from '../../lib/types';

interface TrackEventsConfig {
  trackOnClick?: TrackConfig;
}
export type AnalyticIconButtonProps = IconButtonProps & TrackEventsConfig;

export function IconButton(props: AnalyticIconButtonProps): JSX.Element {
  const analytics = useAnalytics();
  // we could maybe add a check and a warning if analytics is not defined here
  const { trackOnClick, onClick } = props;
  return (
    <BaseIconButton
      {...props}
      onClick={(e) => {
        if (trackOnClick) {
          const { eventName, eventProperties } = trackOnClick;
          analytics.track({
            event: eventName,
            properties: typeof eventProperties === 'function' ? eventProperties() : eventProperties,
          });
        }
        onClick?.(e);
      }}
    />
  );
}
