/* eslint-disable max-len */
export const Chevron = (): JSX.Element => (
  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.73154 7.6945L6.02453 3.4015L10.3175 7.6945C10.5061 7.87665 10.7587 7.97745 11.0209 7.97517C11.2831 7.97289 11.5339 7.86772 11.7193 7.68231C11.9048 7.49691 12.0099 7.24609 12.0122 6.9839C12.0145 6.7217 11.9137 6.4691 11.7315 6.2805L6.73153 1.2805C6.544 1.09302 6.2897 0.987712 6.02453 0.987712C5.75937 0.987712 5.50506 1.09302 5.31753 1.2805L0.317545 6.2805C0.222045 6.37274 0.145845 6.48309 0.0934449 6.60509C0.0410449 6.72709 0.0134443 6.85831 0.0122443 6.99109C0.0111443 7.12387 0.0364444 7.25555 0.0867444 7.37845C0.136944 7.50135 0.211244 7.613 0.305144 7.70689C0.399044 7.80078 0.510645 7.87504 0.633545 7.92532C0.756445 7.9756 0.888145 8.0009 1.02094 7.99975C1.15374 7.99859 1.28494 7.97101 1.40694 7.9186C1.52894 7.86619 1.63924 7.79001 1.73154 7.6945Z"
      fill="currentColor"
    />
  </svg>
);
/* eslint-enable max-len */
