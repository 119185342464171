import type { BaseFetcher, FetcherResponse, RequestOptions } from '@newfront-insurance/browser-fetcher';
import isEqual from 'lodash/fp/isEqual';

export interface MockFetcherDefinition<
  Response = unknown,
  Params = Record<string, string | undefined>,
  RequestBody = unknown,
> {
  request: {
    path: string;
    query?: Params;
    method?: string;
    body?: RequestBody;
  };
  response:
    | {
        body: Response;
      }
    | (() => { body: Response });
  // eslint-disable-next-line
  error?: any;
}

export function createMockFetcherDefinition<
  Response,
  Params = Record<string, string | undefined>,
  RequestBody = unknown,
>(
  definition: MockFetcherDefinition<Response, Params, RequestBody>,
): MockFetcherDefinition<Response, Params, RequestBody> {
  return definition;
}

export function createMockFetcher(baseURL: string, definitions: MockFetcherDefinition[]): BaseFetcher {
  return {
    request: async <Body, Response>({
      url: givenURL,
      method,
      body,
    }: RequestOptions<Body>): Promise<FetcherResponse<Response>> => {
      const url = new URL(`${baseURL}${givenURL}`);
      const foundDefinitions = definitions.filter((definition) => definition.request.path === url.pathname);

      if (foundDefinitions.length === 0) {
        throw new Error(`Could not find mock definition for ${url}`);
      }

      const definition = foundDefinitions.find((def) => {
        const definitionMethod = def.request.method || 'GET';
        const query = def.request.query || {};

        return (
          isEqual(query, Object.fromEntries(url.searchParams)) &&
          isEqual(body || {}, def.request.body || {}) &&
          definitionMethod === method
        );
      });

      if (!definition) {
        // eslint-disable-next-line max-len
        let errorMessage = `Could not match query params, HTTP method or request body for mock definition.\nReceived URL: ${url}\nReceived method: ${method}`;
        if (url.search) {
          errorMessage += `\nReceived query: ${url.search}`;
        }
        if (body) {
          errorMessage += `\nReceived body: ${JSON.stringify(body)}`;
        }
        throw new Error(errorMessage);
      }

      const responseBody =
        typeof definition.response === 'function' ? definition.response().body : definition.response.body;

      return {
        data: responseBody as Response,
        error: definition.error,
      };
    },
  };
}
