import { isDate, isValid } from 'date-fns';
import toDate from 'date-fns-tz/toDate';

import { getLocalTimezone, UTC_TIMEZONE, isDateString, isISOString } from './utils';
import type { DateString, ISODateString } from '../types';

/**
 * We will always format time the same way in every date style.
 */
const TIME_OPTIONS: Intl.DateTimeFormatOptions = {
  hour12: true,
  hour: 'numeric',
  minute: 'numeric',
};

export enum DateFormatStyle {
  /**
   * Example: 1/1/2000 (Localised)
   *
   * All of the date formatters use the Intl API which allows us to render dates
   * according to the users language. For example, anyone using US English it will see month first,
   * whereas anyone outside of the US will see day first.
   */
  STANDARD = 'STANDARD',
  /**
   * Example: 1/1
   *
   * All of the date formatters use the Intl API which allows us to render dates
   * according to the users language. For example, anyone using US English it will see month first,
   * whereas anyone outside of the US will see day first.
   */
  SHORT = 'SHORT',
  /**
   * Example: June 28th 1971 or 28th June 1971
   *
   * All of the date formatters use the Intl API which allows us to render dates
   * according to the users language. For example, anyone using US English it will see month first,
   * whereas anyone outside of the US will see day first.
   */
  MEDIUM = 'MEDIUM',
  /**
   * Tuesday Jun 16, 2020 11:29 PM PDT
   *
   * All of the date formatters use the Intl API which allows us to render dates
   * according to the users language. For example, anyone using US English it will see month first,
   * whereas anyone outside of the US will see day first.
   */
  LONG = 'LONG',
  /**
   * 2021
   */
  YEAR = 'YEAR',
  /**
   * 21
   */
  YEAR_SHORT = 'YEAR_SHORT',
  /**
   * June 1971
   */
  MONTH_YEAR = 'MONTH_YEAR',
  /**
   * June
   */
  MONTH = 'MONTH',
}

/**
 * Maps a DateFormatStyle to set of options that can be passed to Intl.DateTimeFormat().
 */
const FORMAT_OPTIONS: Record<DateFormatStyle, Intl.DateTimeFormatOptions> = {
  [DateFormatStyle.SHORT]: {
    day: 'numeric',
    month: 'numeric',
  },
  [DateFormatStyle.MEDIUM]: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },
  [DateFormatStyle.STANDARD]: {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  },
  [DateFormatStyle.LONG]: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
  },
  [DateFormatStyle.YEAR]: {
    year: 'numeric',
  },
  [DateFormatStyle.YEAR_SHORT]: {
    year: '2-digit',
  },
  [DateFormatStyle.MONTH_YEAR]: {
    month: 'long',
    year: 'numeric',
  },
  [DateFormatStyle.MONTH]: {
    month: 'long',
  },
};

/**
 * Returns the options that can be passed to Intl.DateTimeFormat().
 */
function createFormatterOptions(options: FormatOptions): Intl.DateTimeFormatOptions {
  const { style = DateFormatStyle.STANDARD, timezone, includeTime } = options;
  const timeOptions = includeTime ? TIME_OPTIONS : {};

  return {
    ...FORMAT_OPTIONS[style],
    ...timeOptions,
    timeZone: timezone,
  };
}

export interface FormatOptions {
  locale?: string;
  style?: DateFormatStyle;
  includeTime?: boolean;
  timezone: string;
}

/**
 * Format a date-like string or object into a set format. These use the Intl library so they
 * are formatted according to the users language. There are a fix number of date styles we can
 * use so that they are mostly consistent in the UI.
 *
 * This function will require a timezone to be passed in so that it is not ambiguious about
 * which way the date will be used.
 */
export function formatDate(date: Date | DateString | ISODateString | number, options: FormatOptions): string {
  const { timezone, style = DateFormatStyle.STANDARD } = options;

  if (isDateString(date)) {
    return formatDateWithStyle(
      toDate(date, {
        timeZone: timezone,
      }),
      { ...options, style },
    );
  }

  const isNativeDate = isDate(date) && isValid(date);
  const isTimestamp = typeof date === 'number';

  if (isISOString(date) || isNativeDate || isTimestamp) {
    const dateObj = toDate(date, { timeZone: timezone });
    return formatDateWithStyle(dateObj, { ...options, style });
  }

  throw new Error(`Invalid date: ${date}`);
}

/**
 * This is an internal function that will only accept a date.
 */
export function formatDateWithStyle(date: Date, options: FormatOptions): string {
  const { timezone, locale, style = DateFormatStyle.STANDARD } = options;

  const formatter = new Intl.DateTimeFormat(locale, {
    ...createFormatterOptions({ ...options, style }),
    timeZone: timezone,
  });

  return formatter.format(date);
}

/**
 * Legacy formatting functions. These are kept for backwards compatibility.
 */

/**
 * @deprecated Use formatDate instead
 */
export function standardDateFormat(date: string | number | Date): string {
  return formatDate(date, {
    style: DateFormatStyle.STANDARD,
    timezone: getLocalTimezone(),
    locale: 'en-US',
  });
}

/**
 * @deprecated Use formatDate instead
 */
export function standardUTCDateFormat(date: string | number | Date): string {
  return formatDate(date, {
    style: DateFormatStyle.STANDARD,
    timezone: UTC_TIMEZONE,
    locale: 'en-US',
  });
}

/**
 * @deprecated Use formatDate instead
 */
export function shortDateFormat(date: string | Date): string {
  return formatDate(date, {
    style: DateFormatStyle.SHORT,
    timezone: getLocalTimezone(),
    locale: 'en-US',
  });
}

/**
 * @deprecated Use formatDate instead
 */
export function shortDateTimeFormatWithTimeZone(date: string | Date): string {
  return formatDate(date, {
    style: DateFormatStyle.SHORT,
    includeTime: true,
    timezone: getLocalTimezone(),
    locale: 'en-US',
  });
}

/**
 * @deprecated Use formatDate instead
 */
export function longDateTimeFormatWithTimeZone(date: string | Date): string {
  return formatDate(date, {
    style: DateFormatStyle.LONG,
    includeTime: true,
    timezone: getLocalTimezone(),
    locale: 'en-US',
  });
}

/**
 * @deprecated Use formatDate instead
 */
export function monthDayYear(date: string): string {
  if (!date) return '';
  return formatDate(date, {
    style: DateFormatStyle.MEDIUM,
    includeTime: false,
    timezone: getLocalTimezone(),
    locale: 'en-US',
  });
}

/**
 * @deprecated Use formatDate instead
 */
export function monthDayYearUtc(date: string): string {
  return formatDate(date, {
    style: DateFormatStyle.MEDIUM,
    includeTime: false,
    timezone: UTC_TIMEZONE,
    locale: 'en-US',
  });
}

/**
 * @deprecated Use formatDate instead
 */
export function longDateFormatWithTime(date: string): string {
  return formatDate(date, {
    style: DateFormatStyle.LONG,
    includeTime: false,
    timezone: getLocalTimezone(),
    locale: 'en-US',
  });
}
