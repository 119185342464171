/* eslint-disable react/jsx-props-no-spreading */
import { LogoSpinner } from '@newfront-insurance/core-ui';
import type { AppProps } from 'next/app';
import { Suspense } from 'react';

import { PageErrorBoundary } from '@/client/components/error-boundary';
import { AllProviders } from '@/client/providers';

import '@newfront-insurance/tailwind-config/globals.css';

import '../client/global.css';

export default function App({ Component, pageProps }: AppProps): JSX.Element {
  return (
    <PageErrorBoundary>
      <AllProviders>
        <Suspense fallback={<LogoSpinner />}>
          <Component {...pageProps} />
        </Suspense>
      </AllProviders>
    </PageErrorBoundary>
  );
}
