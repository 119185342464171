import { createNotificationContext } from './lib/create-notification-context';
import type { Notification } from './types';

const {
  NotificationProvider: NotificationContextProvider,
  createMockNotifications,
  useNotifications,
  useNotificationsOffset,
  NotificationContext,
} = createNotificationContext<Notification>();

export {
  NotificationContextProvider,
  createMockNotifications,
  NotificationContext,
  useNotifications,
  useNotificationsOffset,
};
