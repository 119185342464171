import { zIndices } from '@newfront-insurance/core-ui';
import { motion, AnimatePresence } from 'framer-motion';

import { NotificationItem } from './notification-item';
import { useNotifications, useNotificationsOffset } from '../context';

export function NotificationList(): JSX.Element {
  const { entries: notifications } = useNotifications();
  const offsetX = useNotificationsOffset();

  return (
    <div
      style={{
        position: 'fixed',
        zIndex: zIndices.notifications,
        bottom: 16,
        left: '50%',
        marginLeft: offsetX,
        transform: 'translate(-50%, 0)',
      }}
    >
      <AnimatePresence initial={false}>
        {notifications.map((notification) => (
          <motion.div
            key={notification.id}
            initial={{ opacity: 0, y: 50, scale: 0.3 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
          >
            <NotificationItem key={notification.id} id={notification.id} notification={notification.data} />
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
}
