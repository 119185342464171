import { browserLogger } from '@newfront-insurance/dd-rum';
import { CalendarDate, isCalendarDate } from '@newfront-insurance/string-formatters';
import { createTRPCClient as createClient } from '@trpc/client';
import type { TRPCClient, TRPCLink } from '@trpc/client';
import { httpBatchLink } from '@trpc/client/links/httpBatchLink';
import { httpLink } from '@trpc/client/links/httpLink';
import type { AnyRouter } from '@trpc/server';
import superjson from 'superjson';

import { errorLink } from './links/error';
import { loggerLink } from './links/logger';
import { createFetch } from '../fetch';
import type { ClientOptions } from '../types/trpc';

superjson.registerCustom<CalendarDate, string>(
  {
    isApplicable: (v): v is CalendarDate => isCalendarDate(v),
    serialize: (v) => v.toString(),
    deserialize: (v) => new CalendarDate(v),
  },
  'CalendarDate',
);

/**
 * Create a new trpc client that can be used to make requests. This is a wrapper
 * around @trpc/client that adds logging, analytics, and the authorization header.
 */
export function createTRPCClient<Router extends AnyRouter>(options: ClientOptions): TRPCClient<Router> {
  const { getToken, url, batching = false, timeoutInMilliseconds, onError } = options;

  browserLogger.debug('Creating a new trpc client', options);

  const links: TRPCLink<Router>[] = [
    loggerLink({
      enabled: true,
      logger: browserLogger,
    }),
    errorLink({ onError }),
    batching ? httpBatchLink({ url }) : httpLink({ url }),
  ];

  return createClient<Router>({
    transformer: superjson,
    links,
    fetch: createFetch({
      getToken,
      throwOnError: true,
      timeoutInMilliseconds,
    }),
  });
}
