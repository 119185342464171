// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import type config from '../production';

export type ConfigType = typeof config;

const PROD_DOMAINS = ['dash.newfront.com', 'eks-dash.newfront.com', 'app.newfront.com', 'eks-app.newfront.com'];

const STAGING_DOMAINS = ['dash.newfront-staging.com', 'app.newfront-staging.com'];

export function getEnvFromUrl(): 'stage' | 'prod' | 'dev' | undefined {
  if (typeof window === 'undefined') {
    return;
  }

  const url = new URL(window.location.href);
  const subdomain = url.hostname.split('.')[0];

  if (subdomain.startsWith('dev')) {
    return 'dev';
  }

  if (subdomain.startsWith('stage') || STAGING_DOMAINS.includes(url.hostname)) {
    return 'stage';
  }

  if (subdomain.startsWith('prod') || PROD_DOMAINS.includes(url.hostname)) {
    return 'prod';
  }
}
