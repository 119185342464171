export class NetworkError extends Error {
  public request: Request;

  constructor(request: Request) {
    super('Request failed due to network error');
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = 'NetworkError';
    this.request = request;
  }
}
