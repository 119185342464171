import kebabCase from 'lodash/kebabCase';
import * as React from 'react';

import { generateTestId } from '../../lib/test-helpers';
import { Box } from '../box';
import { Flexbox } from '../flexbox';
import { IconButton } from '../icon-button';
import { Padding } from '../padding';
import { Stack } from '../stack';
import { Text } from '../text';
import { Title } from '../title';

export interface ModalHeaderProps {
  onClose: () => unknown;
  titleText: string;
  style?: React.CSSProperties;
  showBorder?: boolean;
  testId?: string;
  subtitle?: string;
  actions?: React.ReactNode[];
}

export function ModalHeader(props: ModalHeaderProps): JSX.Element {
  const { onClose, titleText, showBorder = true, testId: propTestId, subtitle, actions = [] } = props;
  const testId = propTestId ?? generateTestId(kebabCase(titleText), 'modal-header');

  return (
    <Box borderBottom={showBorder ? 1 : 0} testId={testId}>
      <Padding size={24}>
        <Flexbox justifyContent="space-between">
          <Stack gap={8} direction="vertical">
            <Title size={17} testId={`${testId}-title`} style={props.style}>
              {titleText}
            </Title>
            {subtitle ? (
              <Text color="light" testId={`${testId}-subtitle`}>
                {subtitle}
              </Text>
            ) : null}
          </Stack>
          {onClose ? (
            <Flexbox justifyContent="space-between" gap={8}>
              {actions && (
                <Flexbox alignContent="center" gap={8}>
                  {actions}
                </Flexbox>
              )}
              <IconButton
                type="close"
                onClick={onClose}
                testId={generateTestId(testId, 'close-button')}
                focusStyle={{
                  border: '2px solid #245FCC',
                }}
              />
            </Flexbox>
          ) : null}
        </Flexbox>
      </Padding>
    </Box>
  );
}
