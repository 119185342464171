import type { DecodedJwt } from '@newfront-insurance/next-auth-api-types';

export function parseTokenPayload<T>(token: string): T {
  const [, payload] = token.split('.');
  const payloadStr = atob(payload);
  return JSON.parse(payloadStr) as T;
}

export function isTokenExpired(token?: string): boolean {
  if (!token) return true;
  const payload = parseTokenPayload<DecodedJwt>(token);
  return payload.exp * 1000 <= Date.now();
}
