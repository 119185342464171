import { colors, Stack } from '@newfront-insurance/core-ui';
import type { NextApiErrorLike } from '@newfront-insurance/data-layer-http';
import styled from 'styled-components';

import { isNextApiErrorLike } from '../../../next-api-error/error';

interface ErrorDetailsProps {
  error: NextApiErrorLike | Error;
}

/**
 * Render more information about the error, including the stack trace.
 */
export function ErrorDetails({ error }: ErrorDetailsProps): JSX.Element {
  if (isNextApiErrorLike(error)) {
    return (
      <Stack gap={8} direction="vertical">
        <StyledPre>
          {error.method} {error.url}
        </StyledPre>
        <StyledPre>{JSON.stringify(error.data, null, 2)}</StyledPre>
        <StyledPre>{error.stack}</StyledPre>
      </Stack>
    );
  }
  return (
    <Stack gap={8} direction="vertical">
      <StyledPre>{error.message}</StyledPre>
      <StyledPre>{error.stack}</StyledPre>
    </Stack>
  );
}

const StyledPre = styled.pre`
  background-color: ${colors.steel[100]};
  display: block;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  line-height: 16px;
  color: ${colors.steel[400]};
  overflow-x: scroll;
  margin: 0;
`;
