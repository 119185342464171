/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react/jsx-props-no-spreading */
import type { InputProps } from '@newfront-insurance/core-ui';
import { Input as BaseInput } from '@newfront-insurance/core-ui';
import { useAnalytics } from '@newfront-insurance/data-layer-client';
import { useDebounce } from 'react-use';

import type { TrackConfig } from '../../lib/types';

interface TrackEventsConfig {
  trackOnKeyDown?: TrackConfig;
  trackOnChange?: TrackConfig;
  trackOnFocus?: TrackConfig;
  trackOnBlur?: TrackConfig;
  trackOnInput?: TrackConfig;
}

export type AnalyticModalHeaderProps = InputProps & TrackEventsConfig;

export function Input(props: AnalyticModalHeaderProps): JSX.Element {
  const {
    trackOnKeyDown,
    trackOnChange,
    trackOnFocus,
    trackOnBlur,
    trackOnInput,
    value,
    onKeyDown,
    onFocus,
    onBlur,
    onInput,
  } = props;
  const analytics = useAnalytics();

  useDebounce(
    () => {
      if (trackOnChange) {
        analytics.track({
          event: trackOnChange?.eventName,
          properties:
            typeof trackOnChange?.eventProperties === 'function'
              ? trackOnChange.eventProperties(value)
              : trackOnChange?.eventProperties,
        });
      }
    },
    500,
    [value],
  );

  return (
    <BaseInput
      {...props}
      onKeyDown={(e) => {
        if (trackOnKeyDown) {
          analytics.track({
            event: trackOnKeyDown.eventName,
            properties:
              typeof trackOnKeyDown.eventProperties === 'function'
                ? trackOnKeyDown.eventProperties(e)
                : trackOnKeyDown.eventProperties,
          });
        }
        onKeyDown?.(e);
      }}
      onFocus={() => {
        if (trackOnFocus) {
          analytics.track({
            event: trackOnFocus.eventName,
            properties:
              typeof trackOnFocus.eventProperties === 'function'
                ? trackOnFocus.eventProperties()
                : trackOnFocus.eventProperties,
          });
        }
        onFocus?.();
      }}
      onBlur={(newValue) => {
        if (trackOnBlur) {
          analytics.track({
            event: trackOnBlur.eventName,
            properties:
              typeof trackOnBlur.eventProperties === 'function'
                ? trackOnBlur.eventProperties(newValue)
                : trackOnBlur.eventProperties,
          });
        }
        onBlur?.(newValue);
      }}
      onInput={(e) => {
        if (trackOnInput) {
          analytics.track({
            event: trackOnInput.eventName,
            properties:
              typeof trackOnInput.eventProperties === 'function'
                ? trackOnInput.eventProperties(e)
                : trackOnInput.eventProperties,
          });
        }
        onInput?.(e);
      }}
    />
  );
}
