import { ExpandableRow as BaseExpandableRow, ExpandableHead, ExpandableBody } from '@newfront-insurance/core-ui';
import type { ExpandableRowProps } from '@newfront-insurance/core-ui';
import { useAnalytics } from '@newfront-insurance/data-layer-client';

import type { TrackConfig } from '../../lib/types';

interface TrackEventsConfig {
  trackOnExpand?: TrackConfig;
  trackOnCollapse?: TrackConfig;
  trackOnToggle?: TrackConfig;
}
export type AnalyticExpandableRowProps = ExpandableRowProps & TrackEventsConfig;

export function ExpandableRow(props: AnalyticExpandableRowProps): JSX.Element {
  const { track } = useAnalytics();
  const { onToggleRow, trackOnToggle, trackOnCollapse, trackOnExpand, children } = props;
  return (
    <BaseExpandableRow
      {...props}
      onToggleRow={(isExpanded) => {
        if (trackOnToggle) {
          const { eventName, eventProperties } = trackOnToggle;
          track({
            event: eventName,
            properties: typeof eventProperties === 'function' ? eventProperties() : eventProperties,
          });
        }

        const trackerForExpandedStatus = isExpanded ? trackOnExpand : trackOnCollapse;
        if (trackerForExpandedStatus) {
          const { eventName, eventProperties } = trackerForExpandedStatus;
          track({
            event: eventName,
            properties: typeof eventProperties === 'function' ? eventProperties() : eventProperties,
          });
        }
        onToggleRow?.(isExpanded);
      }}
    >
      {children}
    </BaseExpandableRow>
  );
}

// export(ing) the below two as well so the users of the base ExpandableRow can import all these from core-ui-analytics package
export { ExpandableHead, ExpandableBody };
