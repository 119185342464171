import type { NextApiError } from '@newfront-insurance/next-api-error';
import type { SWRResponse, SWRConfiguration } from 'swr';
import useSWR from 'swr';

import { useNextApiFetcher } from './config';
import { useNextApiBaseUrl } from './util';

/**
 * The SWR response from the Next API
 */
export type NextApiSubscription<T> = SWRResponse<T, NextApiError>;

/**
 * Make a GET request to the Next API. Returns a NextApiError if the response fails.
 * @param url Absolute API url, e.g. `/api/foo/bar`. The Next.js basePath will automatically be added.
 * @param options Fetching options
 */
export function useNextApiSubscription<T>(url: string | null, config?: SWRConfiguration): NextApiSubscription<T>;
export function useNextApiSubscription<T>(url: string | null, config?: SWRConfiguration): NextApiSubscription<T> {
  const baseUrl = useNextApiBaseUrl();
  const fetcher = useNextApiFetcher();

  async function fetch(): Promise<T> {
    if (url === null) return null as T;

    const { data, error } = await fetcher.request<never, T>({ url, method: 'GET' });
    if (error) {
      throw error;
    }
    return data;
  }
  return useSWR<T, NextApiError>(url === null ? null : `${baseUrl}${url}`, fetch, config);
}
