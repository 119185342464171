import type { TRPCErrorResponse } from '@newfront-insurance/data-layer-server';
import type { ApplicationErrorLike, ErrorTypes } from '@newfront-insurance/errors';
import { isFetcherErrorLike } from '@newfront-insurance/fetcher';
import type { TRPCClientError, TRPCClientErrorLike } from '@trpc/client';
import type { AnyRouter } from '@trpc/server';
import get from 'lodash/get';
import isObject from 'lodash/isObject';

import { HTTPError } from './http-error';
import type { NotLoggedInError } from './logged-in';
import type { NetworkError } from './network';
import type { NotFoundError } from './not-found';
import type { PermissionError } from './permission';
import type { TimeoutError } from './timeout';

export function isHTTPError(error: unknown): error is HTTPError<unknown> {
  return error instanceof Error && error.name === 'HTTPError';
}

export function isNetworkError(error: unknown): error is NetworkError {
  return error instanceof Error && error.name === 'NetworkError';
}

export function isPermissionError(error: unknown): error is PermissionError {
  if (error instanceof Error && error.name === 'PermissionError') {
    return true;
  }
  if (isHTTPError(error)) {
    return error.response.status === 402 || error.response.status === 403;
  }
  return false;
}

export function isTimeoutError(error: unknown): error is TimeoutError {
  if (error instanceof Error && error.name === 'TimeoutError') {
    return true;
  }
  if (isHTTPError(error)) {
    return error.response.status === 408;
  }
  return false;
}

export function isNotFoundError(error: unknown): error is NotFoundError {
  if (error instanceof Error && error.name === 'NotFoundError') {
    return true;
  }
  if (isHTTPError(error)) {
    return error.response.status === 404;
  }
  return false;
}

export function isNotLoggedInError(error: unknown): error is NotLoggedInError {
  if (error instanceof Error && error.name === 'NotLoggedInError') {
    return true;
  }
  if (isHTTPError(error)) {
    return error.response.status === 401;
  }
  return false;
}

/**
 * Checks to see if an error is any type of data layer error. This could be a network error,
 * a timeout error, a permission error, a not found error, or a not logged in error.
 */
export function isDataLayerError(error: unknown): error is DataLayerError<unknown> {
  if (isHTTPError(error) || isNetworkError(error) || isTimeoutError(error)) {
    return true;
  }
  if (isNotLoggedInError(error) || isNotFoundError(error) || isPermissionError(error)) {
    return true;
  }
  return false;
}

interface CommonFetcherResponse {
  message: string;
}

/**
 * This represents any error that might occur when loading data. These errors are automatically
 * throw by TRPC when a request fails. You can also manually throw one of these errors to trigger
 * the error boundary or add more information to an error.
 */
export type DataLayerError<T = unknown> =
  | HTTPError<T>
  | NetworkError
  | TimeoutError
  | NotLoggedInError
  | NotFoundError
  | PermissionError;

/**
 * Get the preferred error message from an error object. If the error is
 * a HTTP error, the error message from the downstream API service will be returned if it exists.
 * If not, it will try to return the error message from the API client in the BFF.
 */
export function getPreferredErrorMessage(error: TRPCClientErrorLike<AnyRouter> | Error): string {
  // The TRPCClientError will wrap other types of errors, so we
  // really want the error message of the cause.
  if (isTRPCClientError(error) && error.cause) {
    return getPreferredErrorMessage(error.cause);
  }

  // If it's a HTTP error we should try to get the error message from the downstream service.
  // This will give us more information that a generic HTTP error message.
  if (isHTTPError(error)) {
    const body = error.responseBody;
    if (isTRPCErrorResponse(body)) {
      const { applicationError, fetcherError } = body.error.json;

      if (applicationError) {
        return applicationError.message;
      }

      if (isFetcherErrorLike(fetcherError)) {
        return (fetcherError.fetcherResponse.data as CommonFetcherResponse)?.message ?? fetcherError.message;
      }

      return error.message;
    }
  }

  // Default to using the standard error message.
  return error.message;
}

/**
 * Check if an error is a TRPCClientError. This is a special error that is thrown
 * when the TRPC client encounters an error during a HTTP request.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isTRPCClientError(error: unknown): error is TRPCClientError<any> {
  return error instanceof Error && error.name === 'TRPCClientError';
}

/**
 * Checks to see if an object looks like a TRPC error response body. This is the shape
 * that is returned from the BFF when there is an error in a resolver.
 */
export function isTRPCErrorResponse(responseBody: unknown): responseBody is TRPCErrorResponse {
  if (!isObject(responseBody)) {
    return false;
  }
  return !!get(responseBody, 'error.json');
}

/**
 * Checks whether the passed-in error is included in a list of passed-in application error codes.
 * Can be used to surface mutation errors to the user.
 */
export function isApplicationErrorCode(error: unknown, validationErrorCode: string | string[]): boolean {
  const errorCause = isTRPCClientError(error) && error.cause ? error.cause : error;

  if (errorCause instanceof HTTPError) {
    const body = errorCause.responseBody;

    if (isTRPCErrorResponse(body)) {
      const { fetcherError } = body.error.json;

      if (isFetcherErrorLike(fetcherError)) {
        const { statusCode, data } = fetcherError.fetcherResponse;

        if (statusCode >= 400 && typeof data === 'object' && data !== null && 'errorCode' in data) {
          const { errorCode } = data as {
            errorCode: string;
          };

          return validationErrorCode.includes(errorCode);
        }
      }
    }
  }

  return false;
}

/**
 * Extracts the Application Error from a TRPC error.
 */
export function getApplicationError(error: unknown): ApplicationErrorLike<ErrorTypes> | undefined {
  const errorCause = isTRPCClientError(error) && error.cause ? error.cause : error;

  if (errorCause instanceof HTTPError) {
    const body = errorCause.responseBody;
    if (isTRPCErrorResponse(body)) {
      return body.error.json?.applicationError;
    }
  }

  return undefined;
}
