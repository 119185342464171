export const colors = {
  steel: {
    500: '#262D46',
    400: '#546A83',
    300: '#98A6B5',
    200: '#BEC7D0',
    150: '#BEC7D050',
    100: '#F4F5F6',
    75: '#DEE2E7',
    50: '#FAFBFC',
  },
  glitter: {
    100: '#E2EBF1',
    200: '#FFFDE7',
    500: '#CD5615',
  },
  brand: {
    400: '#0957C3',
    300: '#257FFB',
    200: '#A8CBFB',
    100: '#F4F8FE',
  },
  peach: {
    500: '#FFAB7A',
    600: '#C5500D',
  },
  fire: {
    600: '#DF0762',
    500: '#FF4773',
    400: '#FF7A7A',
    300: '#FCC3A2',
    200: '#FFF3CA',
    100: '#FFFCF0',
  },
  grass: {
    600: '#0E5464',
    500: '#1C907C',
    400: '#1CB779',
    300: '#97E4A8',
    200: '#C3FFC1',
    100: '#EFFFEB',
    50: '#EFFBF2',
  },
  purple: {
    100: '#F6F2FF',
    500: '#966DEE',
  },
  border: {
    // steel[150]
    default: '#BEC7D050',
    2: '#dbdbdb',
  },
  rebrand: {
    400: '#F028B8',
    300: '#FC47C9',
  },
};

export type Color = keyof typeof colors;
