import kebabCase from 'lodash/kebabCase';

/**
 *
 * @param testId
 * @param suffix
 * @returns Concat between testId and suffix in kebab case, separated by a dash
 */
export function generateTestId(testId: string | undefined, suffix: string | undefined): string | undefined {
  if (
    typeof testId === 'undefined' ||
    typeof suffix === 'undefined' ||
    testId === null ||
    suffix === null ||
    testId === '' ||
    suffix === ''
  ) {
    return undefined;
  }

  return `${testId}-${kebabCase(suffix)}`;
}
