/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react/jsx-props-no-spreading */
import type { DetailsModalProps } from '@newfront-insurance/core-ui';
import { DetailsModal as BaseDetailsModal } from '@newfront-insurance/core-ui';
import { useAnalytics } from '@newfront-insurance/data-layer-client';
import { useDeepCompareEffect } from 'react-use';

import type { TrackConfig } from '../../lib/types';

interface TrackEventsConfig {
  trackOnToggle?: TrackConfig;
  trackOnHandleBack?: TrackConfig;
  trackOnHandleEdit?: TrackConfig;
  trackOnHandleEmail?: TrackConfig;
  trackOnOpen?: TrackConfig;
}

export type AnalyticDetailsModalProps = DetailsModalProps & TrackEventsConfig;

export function DetailsModal(props: AnalyticDetailsModalProps): JSX.Element {
  const {
    trackOnToggle,
    trackOnHandleBack,
    trackOnHandleEdit,
    trackOnHandleEmail,
    visible,
    trackOnOpen,
    onToggle,
    handleBack,
    handleEdit,
    handleEmail,
    children,
  } = props;
  const analytics = useAnalytics();

  useDeepCompareEffect(() => {
    if (visible && trackOnOpen) {
      analytics.track({
        event: trackOnOpen.eventName,
        properties:
          typeof trackOnOpen.eventProperties === 'function'
            ? trackOnOpen.eventProperties()
            : trackOnOpen.eventProperties,
      });
    }
  }, [visible, analytics, trackOnOpen]);

  return (
    <BaseDetailsModal
      {...props}
      onToggle={() => {
        if (trackOnToggle) {
          analytics.track({
            event: trackOnToggle.eventName,
            properties:
              typeof trackOnToggle.eventProperties === 'function'
                ? trackOnToggle.eventProperties()
                : trackOnToggle.eventProperties,
          });
        }
        onToggle();
      }}
      handleBack={() => {
        if (trackOnHandleBack) {
          analytics.track({
            event: trackOnHandleBack.eventName,
            properties:
              typeof trackOnHandleBack.eventProperties === 'function'
                ? trackOnHandleBack.eventProperties()
                : trackOnHandleBack.eventProperties,
          });
        }
        handleBack?.();
      }}
      handleEdit={() => {
        if (trackOnHandleEdit) {
          analytics.track({
            event: trackOnHandleEdit.eventName,
            properties:
              typeof trackOnHandleEdit.eventProperties === 'function'
                ? trackOnHandleEdit.eventProperties()
                : trackOnHandleEdit.eventProperties,
          });
        }
        handleEdit?.();
      }}
      handleEmail={() => {
        if (trackOnHandleEmail) {
          analytics.track({
            event: trackOnHandleEmail.eventName,
            properties:
              typeof trackOnHandleEmail.eventProperties === 'function'
                ? trackOnHandleEmail.eventProperties()
                : trackOnHandleEmail.eventProperties,
          });
        }
        handleEmail?.();
      }}
    >
      {children}
    </BaseDetailsModal>
  );
}
