import type { ErrorInfo } from 'react';
import * as React from 'react';

interface Props {
  children: (error?: Error) => React.ReactNode;
  onError?: (error: Error, errorInfo?: ErrorInfo) => unknown;
}

interface State {
  error: Error | null;
  hasError: boolean;
}

/**
 * Wrap the app in this error boundary and any errors that are thrown outside of event handlers will be caught here.
 * This is useful for catching errors thrown when making requests to the API.
 */
export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  // Update state so the next render will show the fallback UI.
  static getDerivedStateFromError(error: Error): Partial<State> {
    if (error) {
      return {
        hasError: true,
        error,
      };
    }
    return {
      hasError: false,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const { onError } = this.props;

    // You can also log the error to an error reporting service
    if (onError) {
      onError(error, errorInfo);
    }
  }

  render(): React.ReactNode {
    const { children } = this.props;
    const { error, hasError } = this.state;

    if (hasError) {
      return children(error || undefined);
    }

    return children();
  }
}
