import type { FunctionComponent } from 'react';
import * as React from 'react';

import type { PropsWithChildrenRequired } from './types';

interface Props {
  components: Array<React.FunctionComponent<PropsWithChildrenRequired<unknown>>>;
  children: React.ReactNode;
}

/**
 * Simple component that composes components together.
 */
export function Compose(props: Props): JSX.Element {
  const { components = [], children } = props;
  return (
    <>
      {components.reduceRight(
        // eslint-disable-next-line @typescript-eslint/naming-convention
        (acc, Component) => (
          <Component>{acc}</Component>
        ),
        children,
      )}
    </>
  );
}

/**
 * Create a container component that composes all of the sub-components together.
 */
export function createContainer(providers: FunctionComponent<PropsWithChildrenRequired<unknown>>[]) {
  return function Container(props: PropsWithChildrenRequired<unknown>): JSX.Element {
    const { children } = props;
    return <Compose components={providers}>{children}</Compose>;
  };
}
