import { useRouter } from 'next/router';

export function isObjectLike(value: unknown): value is Record<string, unknown> {
  return typeof value === 'object' && value !== null;
}

export function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export const isServer = (): boolean => typeof window === 'undefined';

/**
 * The prefix to every API url, e.g. /api
 * It will automatically include the router baseUrl
 */
export function useNextApiBaseUrl(): string {
  const router = useRouter();
  const basePath = router && router.basePath ? router.basePath : '';
  return basePath;
}
