/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useContext } from 'react';
import * as React from 'react';

import type { Queue } from './queue';
import { createMutableQueue } from './queue';
import type { QueueHook } from './queue-hooks';
import { useImmutableQueue } from './queue-hooks';

export interface NotificationProviderProps {
  children: React.ReactNode;
  // eslint-disable-next-line react/no-unused-prop-types
  offsetX?: number;
}

export interface MockNotificationProviderProps<T> {
  notifications?: Queue<T>;
  children: React.ReactNode;
}

export interface NotificationContext<T> {
  NotificationContext: React.Context<NotificationContextType<T> | null>;
  NotificationProvider: (props: NotificationProviderProps) => JSX.Element;
  useNotifications: () => QueueHook<T>;
  useNotificationsOffset: () => number | undefined;
  createMockNotifications(): MockNotificationContext<T>;
}

export interface MockNotificationContext<T> {
  MockNotificationProvider: (props: NotificationProviderProps) => JSX.Element;
  queue: Queue<T>;
}

export interface NotificationContextType<T> {
  queue: QueueHook<T>;
  offsetX?: number;
}
/**
 * Create a React context and hook for accessing it typed to your notification shape.
 *
 *      const { NotificationQueueProvider, useNotifications } = createNotificationContext<Notification>();
 *
 * This allows you to skip some of the ceremony needed to setup your own notification renderer.
 */
export function createNotificationContext<Notification>(): NotificationContext<Notification> {
  const NotificationContext = createContext<NotificationContextType<Notification> | null>(null);

  /**
   * This hook allows you to add and remove notifications from within your components.
   */
  function useNotifications(): QueueHook<Notification> {
    const notifications = useContext(NotificationContext);
    if (!notifications) {
      throw new Error('Missing <NotificationProvider>');
    }

    return notifications.queue;
  }

  /**
   * This hook allows you to add and remove notifications from within your components.
   */
  function useNotificationsOffset(): number | undefined {
    const notifications = useContext(NotificationContext);

    return notifications?.offsetX;
  }

  /**
   * This component should wrap your app. It allows components to use the useNotifications hook.
   */
  function NotificationProvider(props: NotificationProviderProps): JSX.Element {
    const { children, offsetX } = props;
    const queue = useImmutableQueue<Notification>();

    return <NotificationContext.Provider value={{ queue, offsetX }}>{children}</NotificationContext.Provider>;
  }

  /**
   * Create a fake notification queue that can be used during tests and stories. Unlike the normal queue this one isn't
   * immutable, so you'll be able to inspect the state of the queue between renders:
   *
   *      const queue = createMockNotifications();
   *
   *      const { findByText } = render(
   *        <MockNotificationProvider queue={queue}>
   *          <MyComponent />
   *        </MockNotificationProvider>
   *      );
   *
   *      const el = await findByText('Submit');
   *
   *      act(() => {
   *        fireEvent.click(el);
   *      })
   *
   *      expect(queue.list.length).toBe(1);
   *      expect(queue.list[0]).toEqual({ id: 'submitting', data: { message: 'Submitting...' } });
   *
   * This will let you test that notifications are correctly firing without needing to look for elements in the DOM.
   */
  function createMockNotifications(): MockNotificationContext<Notification> {
    const queue = createMutableQueue<Notification>();
    function MockNotificationProvider(props: NotificationProviderProps): JSX.Element {
      const { children } = props;
      return <NotificationContext.Provider value={{ queue }}>{children}</NotificationContext.Provider>;
    }
    return {
      MockNotificationProvider,
      queue,
    };
  }

  return {
    NotificationContext,
    NotificationProvider,
    useNotifications,
    useNotificationsOffset,
    createMockNotifications,
  };
}
