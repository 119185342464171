import type { Logger } from '@datadog/browser-logs';
import type { TRPCLink } from '@trpc/client';
import { TRPCClientError } from '@trpc/client';
import type { AnyRouter } from '@trpc/server';

interface LoggerLinkOptions {
  enabled: boolean;
  logger: Logger | Console;
}

/**
 * Logger middleware for @trpc/client that will automatically log information
 * about every request. This can be used to debug the requests being made in your apps.
 */
export function loggerLink(options: LoggerLinkOptions): TRPCLink<AnyRouter> {
  const { enabled, logger } = options;

  return (_runtime) => {
    return ({ next, op, prev }) => {
      const start = Date.now();
      next(op, (result) => {
        // Link is disabled, so bail
        if (!enabled) {
          prev(result);

          return;
        }
        const duration = Date.now() - start;
        if (result instanceof TRPCClientError) {
          logger.error('Request failed', result);
        } else {
          logger.debug(`Request completed in ${duration}ms`, result);
        }
        prev(result);
      });
    };
  };
}
