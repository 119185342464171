/* eslint-disable react/jsx-props-no-spreading */
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

// eslint-disable-next-line import/no-cycle
import { useAnalytics } from './provider';
import type { Properties } from './types';

interface HeartbeatEnrichment {
  domainObjectType?: string;
  domainObjectUuid?: string;
}

interface HeartbeatEvent extends Properties, HeartbeatEnrichment {
  tabId: string;
  startedAt: Date;
  firstAcitivityTime?: Date;
  lastActivityTime?: Date;
  heartbeatId?: string;
  focused: boolean;
  activity: {
    clicksCount: number;
    focusCount: number;
    scrollCount: number;
    keystrokesCount: number;
    pasteCount: number;
    cutCount: number;
    moused: boolean;
    scrolled: boolean;
    unfocusCount: number;
  };
  flags?: string;
}

function createEmptyHeartbeatEvent(
  tabId: string,
  appName: string,
  enrichment?: HeartbeatEnrichment,
  heartbeatId?: string,
  flags?: string,
): HeartbeatEvent {
  return {
    tabId,
    appName,
    startedAt: new Date(),
    heartbeatId,
    domainObjectType: undefined,
    domainObjectUuid: undefined,
    focused: document.visibilityState === 'visible',
    activity: {
      clicksCount: 0,
      focusCount: 0,
      unfocusCount: 0,
      scrollCount: 0,
      pasteCount: 0,
      cutCount: 0,
      keystrokesCount: 0,
      moused: false,
      scrolled: false,
    },
    flags,
    ...enrichment,
  };
}

interface HeartbeatContextValue {
  enrichDomainObject: (enrichment?: HeartbeatEnrichment) => void;
  setIdentifier: (enrichment?: string) => void;
}

const HeartbeatContext = createContext<HeartbeatContextValue>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  enrichDomainObject: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIdentifier: () => {},
});

interface HeartbeatProviderProps {
  appName: string;
  enabled?: boolean;
  children: JSX.Element;
  flags?: Record<string, boolean>;
}

export function HeartbeatProvider({ children, appName, enabled, flags }: HeartbeatProviderProps): JSX.Element {
  const tabId = useRef(Math.random().toString(36).slice(2));
  const [heartbeatEnrichment, setHeartbeatEnrichment] = useState<
    (HeartbeatEnrichment & { hearbeatId?: string }) | undefined
  >();
  const [heartbeatId, setHeartbeatId] = useState<string | undefined>();

  const enrichDomainObject = useCallback((enrichment?: HeartbeatEnrichment) => {
    setHeartbeatEnrichment(enrichment);
  }, []);

  const enrichIdentifier = useCallback((identifier?: string) => {
    setHeartbeatId(identifier);
  }, []);

  const heartbeatContextValue: HeartbeatContextValue = useMemo(
    () => ({
      enrichDomainObject,
      setIdentifier: enrichIdentifier,
    }),
    [enrichDomainObject, enrichIdentifier],
  );

  if (!enabled) {
    return children;
  }

  return (
    <HeartbeatContext.Provider value={heartbeatContextValue}>
      <HeartbeatCollector
        tabId={tabId.current}
        enrichment={heartbeatEnrichment}
        heartbeatId={heartbeatId}
        appName={appName}
        flags={flags}
      />
      {children}
    </HeartbeatContext.Provider>
  );
}

export function useHeartbeatEnrichment() {
  return useContext(HeartbeatContext);
}

interface HeartbeatEnrichmentProps {
  heartbeatId?: string;
  enrichment?: HeartbeatEnrichment;
  children: JSX.Element;
}

export function HeartbeatEnrichment({ enrichment, children }: HeartbeatEnrichmentProps): JSX.Element {
  const { enrichDomainObject } = useHeartbeatEnrichment();

  useEffect(() => {
    enrichDomainObject({
      domainObjectType: enrichment?.domainObjectType,
      domainObjectUuid: enrichment?.domainObjectUuid,
    });
  }, [enrichment?.domainObjectType, enrichment?.domainObjectUuid, enrichDomainObject]);

  return children;
}

interface HeartbeatIdentifierProps {
  identifier?: string;
  children: JSX.Element;
}

export function HeartbeatIdentifier({ identifier, children }: HeartbeatIdentifierProps): JSX.Element {
  const { setIdentifier } = useHeartbeatEnrichment();

  useEffect(() => {
    setIdentifier(identifier);
  }, [identifier, setIdentifier]);

  return children;
}

interface HeartbeatCollectorProps {
  tabId: string;
  enrichment?: HeartbeatEnrichment;
  heartbeatId?: string;
  appName: string;
  flags?: Record<string, boolean>;
}

export function HeartbeatCollector({
  tabId,
  enrichment,
  heartbeatId,
  appName,
  flags,
}: HeartbeatCollectorProps): JSX.Element | null {
  const analytics = useAnalytics();
  const flagsRef = useRef<Record<string, boolean>>({});
  const [, setHeartbeatBatch] = useState<HeartbeatEvent>(() =>
    createEmptyHeartbeatEvent(tabId, appName, enrichment, heartbeatId),
  );
  const interval = useRef<NodeJS.Timeout | null>(null);

  function trackHearbeatEvent(
    updater: (prevState: HeartbeatEvent) => HeartbeatEvent,
    moused = false,
    scrolled = false,
  ): void {
    setHeartbeatBatch((currentHeartbeat) => {
      return updater({
        ...currentHeartbeat,
        firstAcitivityTime: currentHeartbeat.firstAcitivityTime ?? new Date(),
        lastActivityTime: new Date(),
        moused: currentHeartbeat.moused || moused,
        scrolled: currentHeartbeat.scrolled || scrolled,
      });
    });
  }

  useEffect(() => {
    setHeartbeatBatch((currentHeartbeat) => {
      return {
        ...currentHeartbeat,
        domainObjectType: enrichment?.domainObjectType,
        domainObjectUuid: enrichment?.domainObjectUuid,
      };
    });
  }, [enrichment]);

  useEffect(() => {
    flagsRef.current = flags ?? {};
  }, [flags]);

  useEffect(() => {
    function clickListener(): void {
      trackHearbeatEvent(
        (prev) => ({
          ...prev,
          activity: {
            ...prev.activity,
            clicksCount: prev.activity.clicksCount + 1,
          },
        }),
        true,
      );
    }

    function keystrokeListener(): void {
      trackHearbeatEvent((prev) => ({
        ...prev,
        activity: {
          ...prev.activity,
          keystrokesCount: prev.activity.keystrokesCount + 1,
        },
      }));
    }

    function mouseMoveListener(): void {
      trackHearbeatEvent((prev) => prev, true);
    }

    function wheelListener(): void {
      trackHearbeatEvent(
        (prev) => ({
          ...prev,
          activity: {
            ...prev.activity,
            scrollCount: prev.activity.scrollCount + 1,
            scrolled: true,
          },
        }),
        undefined,
        true,
      );
    }

    function pasteListener(): void {
      trackHearbeatEvent((prev) => ({
        ...prev,
        activity: {
          ...prev.activity,
          pasteCount: prev.activity.pasteCount + 1,
        },
      }));
    }

    function cutListener(): void {
      trackHearbeatEvent((prev) => ({
        ...prev,
        activity: {
          ...prev.activity,
          cutCount: prev.activity.cutCount + 1,
        },
      }));
    }

    function focusListener(): void {
      trackHearbeatEvent((prev) => ({
        ...prev,
        activity: {
          ...prev.activity,
          focusCount: document.visibilityState === 'visible' ? prev.activity.focusCount + 1 : prev.activity.focusCount,
          unfocusCount:
            document.visibilityState === 'hidden' ? prev.activity.unfocusCount + 1 : prev.activity.unfocusCount,
        },
      }));
    }

    document.addEventListener('click', clickListener);
    document.addEventListener('mousemove', mouseMoveListener);
    document.addEventListener('keypress', keystrokeListener);
    document.addEventListener('wheel', wheelListener);
    document.addEventListener('paste', pasteListener);
    document.addEventListener('cut', cutListener);
    document.addEventListener('visibilitychange', focusListener);

    return () => {
      document.removeEventListener('click', clickListener);
      document.removeEventListener('mousemove', mouseMoveListener);
      document.removeEventListener('keypress', keystrokeListener);
      document.removeEventListener('wheel', wheelListener);
      document.removeEventListener('paste', pasteListener);
      document.removeEventListener('cut', cutListener);
      document.removeEventListener('visibilitychange', focusListener);
    };
  }, []);

  useEffect(() => {
    if (interval.current) {
      return;
    }

    interval.current = setInterval(() => {
      setHeartbeatBatch((prev) => {
        analytics.track({
          event: 'heartbeat',
          properties: {
            ...prev,
            heartbeatId,
            domainObjectType: prev?.domainObjectType,
            domainObjectUuid: prev?.domainObjectUuid,
            flags: JSON.stringify(flagsRef.current),
          } as HeartbeatEvent,
        });

        return createEmptyHeartbeatEvent(
          tabId,
          appName,
          {
            domainObjectType: prev?.domainObjectType,
            domainObjectUuid: prev?.domainObjectUuid,
          },
          heartbeatId,
        );
      });
    }, 5000);

    const flagsCurrent = flagsRef.current;

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = null;
      }

      setHeartbeatBatch((prev) => {
        analytics.track({
          event: 'heartbeat',
          properties: {
            ...prev,
            heartbeatId: prev.heartbeatId,
            domainObjectType: prev?.domainObjectType,
            domainObjectUuid: prev?.domainObjectUuid,
            flags: JSON.stringify(flagsCurrent),
          } as HeartbeatEvent,
        });

        return createEmptyHeartbeatEvent(
          tabId,
          appName,
          {
            domainObjectType: prev?.domainObjectType,
            domainObjectUuid: prev?.domainObjectUuid,
          },
          heartbeatId,
        );
      });
    };
  }, [setHeartbeatBatch, analytics, tabId, heartbeatId, appName]);

  return null;
}
