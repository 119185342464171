/* eslint-disable react/jsx-props-no-spreading */
import {
  isNotFoundError,
  isNotLoggedInError,
  isPermissionError,
  isTimeoutError,
  getPreferredErrorMessage,
  isTRPCClientError,
  isNetworkError,
} from '@newfront-insurance/data-layer-client';
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { ErrorMessage } from './components/error-message';
import type { ErrorBoundaryMessages, ErrorCallback } from './types';

interface Props {
  children: React.ReactNode;
  messages: ErrorBoundaryMessages;
  onError?: ErrorCallback;
}

export function ErrorBoundaryWithMessages({ children, onError, messages }: Props): JSX.Element {
  return (
    <ErrorBoundary
      onError={onError}
      fallbackRender={({ error }) => {
        const message = getPreferredErrorMessage(error);

        // If it's a TRPCClientError we want to use the cause of the error instead. Otherwise
        // the error message won't be useful.
        const errorCause = isTRPCClientError(error) && error.cause ? error.cause : error;

        if (isPermissionError(errorCause)) {
          return <ErrorMessage {...messages.permissionError(errorCause)} />;
        }
        if (isTimeoutError(errorCause)) {
          return <ErrorMessage {...messages.timeoutError(errorCause)} />;
        }
        if (isNetworkError(errorCause)) {
          return <ErrorMessage {...messages.networkError(errorCause)} />;
        }
        if (isNotFoundError(errorCause)) {
          return <ErrorMessage {...messages.notFoundError(errorCause)} />;
        }
        if (isNotLoggedInError(errorCause)) {
          return <ErrorMessage {...messages.notLoggedInError(errorCause)} />;
        }

        // Capture any other HTTP error, like a 400 or 50x error. This will show the NestJS error message
        // or fetcher error. Any other type error will just show the error.message.
        return <ErrorMessage {...messages.genericError(errorCause)} rawErrorMessage={message} />;
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
