import type { Analytics } from '@segment/analytics-next';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { createContext, useMemo, useContext, useEffect, useState } from 'react';
import * as React from 'react';

import type { SegmentClientOptions } from './client';
import { SegmentClient } from './client';

interface ClientResponse {
  client: SegmentClient;
  isReady: boolean;
}

/**
 * This is the context object that useAnalytics will be grabbing the client from.
 * When you're trying to mock analytics calls, you should pass a fake value here.
 */
export const SegmentContext = createContext<ClientResponse | undefined>(undefined);

/**
 * The provider props. The API key is for the Segment source.
 */

interface SegmentProviderProps {
  apiKey: string;
  children: React.ReactNode;
  clientOptions: SegmentClientOptions;
}

/**
 * Create a Segment client. The client will maintain it's identity while the options are unchanged between renders.
 * @param options
 */
export function useSegmentClient(apiKey: string, options: SegmentClientOptions): ClientResponse {
  const [analytics, setAnalytics] = useState<Analytics | undefined>();
  const [isReady, setIsReady] = useState(false);
  const { anonymizeIp, debug, defaultTrackProperties, timeout, enabled = true } = options;

  // We create the client before we have loaded the analytics.js library
  // so that we can queue up events.
  const client = useMemo(() => {
    const segmentClient = new SegmentClient();
    segmentClient.emitter.on('initialize', () => {
      setIsReady(true);
    });
    return segmentClient;
  }, []);

  // We need to load the real Segment client in the browser. This will need to load a script tag and
  // it sets the segment client on the window. It will also load all of the other libraries that
  // have been enabled in the project in Segment. This is why we're not able to just use an npm
  // package for this.
  useEffect(() => {
    if (!enabled) {
      return;
    }

    AnalyticsBrowser.load({ writeKey: apiKey })
      .then((result) => {
        const [response] = result;
        setAnalytics(response);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.warn('There was a problem loading the Segment snippet. Skipping.', err);
      });
  }, [apiKey]);

  // If any of the options changes, we should update them on the client. We separate this out
  // so that the options can change, but the identity of the client remains stable. This will
  // make it cleaner to avoid duplicate analytics calls later.
  useEffect(() => {
    if (client) {
      client.setOptions({
        anonymizeIp,
        debug,
        defaultTrackProperties,
        timeout,
      });
    }
  }, [client, anonymizeIp, debug, defaultTrackProperties, timeout]);

  // When the underlying Segment client has been loaded on the page, we'll set the
  // global analytics client on our wrapper. This will allow it to actually be used
  // to make analytics calls.
  useEffect(() => {
    if (analytics) {
      client.initialize(analytics);
    }
  }, [client, analytics]);

  return { client, isReady };
}

/**
 * Load the Segment snippet and add it to the app context. This client will be available before the script
 * has finished loading so that it doesn't block page rendering.
 * @param props SegmentProviderProps
 */
export function SegmentClientProvider(props: SegmentProviderProps): JSX.Element {
  const { apiKey, children, clientOptions } = props;

  const client = useSegmentClient(apiKey, clientOptions);

  return <SegmentContext.Provider value={client}>{children}</SegmentContext.Provider>;
}

/**
 * Return the Segment client added by <SegmentProvider>. This provider must be added higher up in the React tree to
 * be able to use this hook.
 */
export function useSegmentClientContext(): ClientResponse {
  const result = useContext(SegmentContext);
  if (!result) {
    throw new Error('The useSegment hook needs <SegmentProvider> to be present higher in the React tree.');
  }
  return result;
}
