import type { ReactNode, RefObject } from 'react';
import { createContext, useContext } from 'react';

const ModalPortalContext = createContext<{ elementRef: RefObject<HTMLElement> | null }>({ elementRef: null });

interface Props {
  children: ReactNode;
  elementRef: RefObject<HTMLElement>;
}

/**
 * This context allows modals to be rendered at a custom container and not only to `document.body`
 */
export function ModalPortalProvider({ children, elementRef }: Props): JSX.Element {
  return <ModalPortalContext.Provider value={{ elementRef }}>{children}</ModalPortalContext.Provider>;
}

export function useModalPortalContainer(): RefObject<HTMLElement> | null {
  const context = useContext(ModalPortalContext);

  return context.elementRef;
}
