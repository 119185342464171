import type React from 'react';

import { isServer } from './utils';

interface Props {
  fallback?: JSX.Element | null;
  children: React.ReactNode;
}

/**
 * Prevent rendering the children when it's rendered on the server. Optionally
 * render a fallback instead.
 */
export function SSRBoundary(props: Props): JSX.Element | null {
  const { fallback = null, children } = props;
  if (isServer()) {
    if (fallback) {
      return fallback;
    }
    return null;
  }
  return children as JSX.Element;
}
