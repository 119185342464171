import { isTokenExpired } from '../../internal/helpers/jwt';
import type { AuthClientPersistor, AuthClientTokens } from '../../types';

export class SessionStoragePersistor implements AuthClientPersistor {
  private readonly keyPrefix: string;

  private readonly storage: Storage;

  public constructor(keyPrefix: string, storage?: Storage) {
    this.keyPrefix = keyPrefix;
    this.storage = storage || window.sessionStorage;
  }

  public set(tokens?: Partial<AuthClientTokens>, keySuffix?: string): void {
    if (tokens) {
      this.storage.setItem(this.getFullKey(keySuffix), JSON.stringify(tokens));
    } else {
      this.storage.removeItem(this.getFullKey(keySuffix));
    }
  }

  public get(keySuffix?: string): Partial<AuthClientTokens> | null {
    const value = this.storage.getItem(this.getFullKey(keySuffix));
    if (!value) {
      return null;
    }
    try {
      const tokens = JSON.parse(value) as AuthClientTokens;
      const { token, idToken, refreshToken } = tokens;
      // Only set the tokens if they actually exist.
      // The auth client might only check to see if the property exists, not that it's actually falsy.
      const partialTokens: Partial<AuthClientTokens> = {};
      if (!isTokenExpired(token)) {
        partialTokens.token = token;
      }
      if (!isTokenExpired(idToken)) {
        partialTokens.idToken = idToken;
      }
      if (!isTokenExpired(refreshToken)) {
        partialTokens.refreshToken = refreshToken;
      }
      return partialTokens;
    } catch (e) {
      return null;
    }
  }

  public clear(keySuffix?: string): void {
    this.storage.removeItem(this.getFullKey(keySuffix));
  }

  private getFullKey(keySuffix?: string): string {
    return keySuffix ? `${this.keyPrefix}-${keySuffix}` : this.keyPrefix;
  }
}
