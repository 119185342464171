import { HTTPError } from './errors/http-error';
import { NetworkError } from './errors/network';
import { TimeoutError } from './errors/timeout';

type Token = string | undefined | null;

export interface FetchOptions {
  baseUrl?: string;
  throwOnError?: boolean;
  getToken?: () => Promise<Token> | Token;
  globalFetch?: typeof fetch;
  timeoutInMilliseconds?: number;
}

/**
 * Create a fetch function with a baseUrl and auth token curried in.
 */
export function createFetch(options: FetchOptions): typeof fetch {
  const { baseUrl, getToken, throwOnError = false, globalFetch = fetch, timeoutInMilliseconds = 10000 } = options;

  return async (input, init) => {
    let token: Token | undefined;
    if (getToken) {
      token = await getToken();
    }

    const requestOptions: RequestInit = {
      ...init,
      headers: {
        ...(init?.headers || {}),
        Authorization: token ? `Bearer ${token}` : '',
      },
    };

    const request = new Request(`${baseUrl || ''}${input}`, requestOptions);

    let response: Response;

    /**
     * We want to handle network and timeout errors during the request. These are
     * taken from Ky and his article: https://medium.com/to-err-is-aaron/detect-network-failures-when-using-fetch-40a53d56e36
     * Retries are handled outside of this helper by TRPC or other HTTP layer.
     */
    try {
      response = await Promise.race([
        globalFetch(request),
        new Promise<Response>((_, reject) => {
          setTimeout(() => reject(new TimeoutError(request)), timeoutInMilliseconds);
        }),
      ]);
    } catch (error) {
      // The only way to detech network errors with fetch is to look for TypeError
      // being thrown. This is part of the Fetch spec. We want to give it a more descriptive
      // error so that we can handle it more gracefully.
      // Normally retries would be implemented here but we have that handled for us by TRPC.
      if (error instanceof Error && error.name === 'TypeError') {
        throw new NetworkError(request);
      }
      // Some other unhandled exception, timeout error, or programmer error.
      throw error;
    }

    // If the response gives us an error code we want to throw a custom error for that
    // so that we can distinguish it from other runtime errors more easily.
    if (!response.ok && response.status >= 400 && throwOnError) {
      const responseBody = await response.json();
      throw new HTTPError(response, request, responseBody, requestOptions);
    }

    return response;
  };
}
