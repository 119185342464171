import classnames from 'classnames';
import kebabCase from 'lodash/kebabCase';
import * as React from 'react';
import styled from 'styled-components';

import { generateTestId } from '../../lib/test-helpers';
import { colors } from '../../theme/colors';
import type { IconSize } from '../icons';
import {
  IconAdd,
  IconArchive,
  IconArrow,
  IconArrowClock,
  IconArrowDropDown,
  IconArrowDropUp,
  IconAssociate,
  IconAttach,
  IconBind,
  IconCaretLeft,
  IconChevronLeft,
  IconChevronRight,
  IconCircleInformation,
  IconClock,
  IconClose,
  IconCopy,
  IconDate,
  IconDownload,
  IconEdit,
  IconEmail,
  IconEyeClosed,
  IconEyeOpen,
  IconIdCard,
  IconMail,
  IconMore,
  IconMoveLeft,
  IconMoveLeftRight,
  IconMoveRight,
  IconPencil,
  IconPreview,
  IconQuestionMark,
  IconRegenerate,
  IconRemove,
  IconSort,
  IconSquareExternal,
  IconTag,
  IconUnarchive,
  IconUnlink,
  IconVerticalSwap,
  IconCopyPlus,
} from '../icons';

export type IconType =
  | 'add'
  | 'archive'
  | 'arrow'
  | 'arrowClock'
  | 'arrowDropDown'
  | 'arrowDropUp'
  | 'associate'
  | 'attach'
  | 'bind'
  | 'caretLeft'
  | 'chevronLeft'
  | 'chevronRight'
  | 'circleInformation'
  | 'clock'
  | 'close'
  | 'copy'
  | 'copyLogging'
  | 'copyPlus'
  | 'date'
  | 'download'
  | 'edit'
  | 'editPencil'
  | 'external'
  | 'hidden'
  | 'hidePassword'
  | 'idCard'
  | 'mail'
  | 'more'
  | 'moveLeft'
  | 'moveLeftRight'
  | 'moveRight'
  | 'pencil'
  | 'preview'
  | 'questionMark'
  | 'regenerate'
  | 'remove'
  | 'showPassword'
  | 'sort'
  | 'tag'
  | 'unarchive'
  | 'unlink'
  | 'verticalSwap'
  | 'visible';

export interface IconButtonProps {
  ariaLabel?: string;
  color?: string;
  disabled?: boolean;
  elementRef?: React.RefObject<HTMLElement>;
  href?: string;
  iconSize?: IconSize;
  innerRef?: React.RefObject<HTMLAnchorElement>;
  isActive?: boolean;
  onClick?: (e: React.SyntheticEvent) => unknown;
  onMouseOver?: (e: React.SyntheticEvent) => unknown;
  size?: 24 | 32;
  style?: React.CSSProperties;
  target?: string;
  testId?: string;
  title?: string;
  type: IconType;
  focusStyle?: { [key: string]: string };
}

const iconTypes: Record<IconType, React.FunctionComponent<{ color?: string; size?: IconSize }>> = {
  add: IconAdd,
  archive: IconArchive,
  arrow: IconArrow,
  arrowClock: IconArrowClock,
  arrowDropDown: IconArrowDropDown,
  arrowDropUp: IconArrowDropUp,
  associate: IconAssociate,
  attach: IconAttach,
  bind: IconBind,
  caretLeft: IconCaretLeft,
  chevronLeft: IconChevronLeft,
  chevronRight: IconChevronRight,
  circleInformation: IconCircleInformation,
  clock: IconClock,
  close: IconClose,
  copy: IconCopy,
  copyLogging: IconEmail,
  copyPlus: IconCopyPlus,
  date: IconDate,
  download: IconDownload,
  edit: IconEdit,
  editPencil: IconPencil,
  external: IconSquareExternal,
  hidden: IconEyeClosed,
  hidePassword: IconEyeClosed,
  idCard: IconIdCard,
  mail: IconMail,
  more: IconMore,
  moveLeft: IconMoveLeft,
  moveLeftRight: IconMoveLeftRight,
  moveRight: IconMoveRight,
  pencil: IconPencil,
  preview: IconPreview,
  questionMark: IconQuestionMark,
  regenerate: IconRegenerate,
  remove: IconRemove,
  showPassword: IconEyeOpen,
  sort: IconSort,
  tag: IconTag,
  unarchive: IconUnarchive,
  unlink: IconUnlink,
  verticalSwap: IconVerticalSwap,
  visible: IconEyeOpen,
};

export function IconButton(props: IconButtonProps): JSX.Element {
  const {
    ariaLabel,
    color,
    disabled = false,
    elementRef,
    href,
    iconSize,
    innerRef,
    isActive,
    onClick,
    onMouseOver,
    size = 24,
    style,
    target,
    testId: propTestId,
    title,
    type,
    focusStyle,
  } = props;
  const Icon = iconTypes[type];

  const classes = classnames({
    isActive,
  });

  const keyEventHandler = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (e.key === 'Enter') {
      onClick?.({} as React.MouseEvent<HTMLAnchorElement>);
    }
  };

  const testId = propTestId ?? generateTestId(kebabCase(ariaLabel ?? title ?? type), 'button');

  return (
    <StyledAnchor
      className={classes}
      onClick={!disabled ? onClick : undefined}
      onMouseOver={!disabled ? onMouseOver : undefined}
      href={!disabled ? href : undefined}
      target={target}
      ref={(elementRef as React.RefObject<HTMLAnchorElement>) || innerRef}
      data-testid={testId}
      disabled={disabled}
      aria-label={ariaLabel || 'Remove'}
      title={title}
      size={size}
      style={style}
      role="button"
      tabIndex={0}
      focusStyle={focusStyle}
      onKeyDown={keyEventHandler}
    >
      <Icon color={color} size={iconSize} />
    </StyledAnchor>
  );
}

const StyledAnchor = styled.a<{ disabled: boolean; size: 24 | 32; focusStyle?: { [key: string]: string } }>`
  border-radius: 50%;
  height: ${({ size }) => (size ? `${size}px` : '24px')};
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${colors.steel[400]};
  padding: 0;
  border: 0;
  background: none;
  pointer-events: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1.0')};
  transition:
    background 0.3s ease,
    color 0.3s ease;

  :global(*) {
    cursor: pointer;
    pointer-events: none;
  }

  :hover,
  &.isActive {
    background: ${({ disabled }) => (disabled ? 'none' : colors.steel[100])};
  }

  :focus {
    outline: none;
    ${({ focusStyle }) =>
      focusStyle &&
      Object.keys(focusStyle)
        .map((key) => `${key}: ${focusStyle[key]};`)
        .join('')}
  }
`;
