import type { ReactNode } from 'react';

import { NotificationList } from './components/notification-list';
import { NotificationContextProvider } from './context';

interface Props {
  children: ReactNode;
  offsetX?: number;
}

export function NotificationProvider(props: Props): JSX.Element {
  const { children, offsetX } = props;
  return (
    <NotificationContextProvider offsetX={offsetX}>
      {children}
      <NotificationList />
    </NotificationContextProvider>
  );
}
