import { isBefore, isAfter, addHours, subHours, isValid, parse } from 'date-fns';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import toDate from 'date-fns-tz/toDate';
import isDate from 'lodash/isDate';
import isObject from 'lodash/isObject';

import type { FormatOptions } from './formats';
import { DateFormatStyle, formatDateWithStyle } from './formats';
import type { DateInputFormat } from './utils';
import {
  prependWithZero,
  UTC_TIMEZONE,
  isISOString,
  isDateString,
  isCalendarDateObject,
  getLocalTimezone,
} from './utils';
import type { CalendarDateObject, DateString, ISODateString } from '../types';

export interface CalendarDateValidationOptions {
  allowWeekends?: boolean;
  minDate?: CalendarDate;
  maxDate?: CalendarDate;
}

/**
 * Check to see if a value is CalendarDate
 */
export function isCalendarDate(date: unknown): date is CalendarDate {
  return isObject(date) && 'calendarDateValue' in date && isDateString((date as CalendarDate).calendarDateValue);
}

/**
 * Convert a date object into a date string in the form yyyy-MM-dd.
 */
function dateToDateString(date: Date, timezone: string): DateString {
  return formatInTimeZone(date, timezone, 'yyyy-MM-dd');
}

/**
 * Convert a CalendarDateObject into a date string in the form yyyy-MM-dd.
 */
function calendarDateObjectToDateString(obj: CalendarDateObject): DateString {
  return `${obj.year}-${prependWithZero(obj.month)}-${prependWithZero(obj.day)}`;
}

/**
 * This can be passed to a yup scheme to validate that a form field is a valid
 * date string given the constraints.
 *
 *    const schema = yup().object({
 *      date: yup().string().test('isValidDate', '${path} must be a valid date string after 1st Jan 2020 and not on a weekend', validateDateString({
 *        allowWeekends: false,
 *        minDate: '2020-01-01',
 *      }))
 *    });
 */
export function createCalendarDateValidator(options: CalendarDateValidationOptions) {
  return (value: string): boolean => {
    const calendarDate = new CalendarDate(value);
    return calendarDate.check(options);
  };
}

/**
 * This represents a single calendar date without a time. This should be used when
 * you're working with dates.
 */
export class CalendarDate {
  /**
   * @deprecated Not meant to be used directly, use `toDate()` instead
   */
  calendarDateValue: DateString;

  /**
   * Takes any user input and parses a date from it.
   */
  static fromString(str: string, format: string): CalendarDate | null {
    try {
      const date = parse(str, format, new Date());
      if (!isValid(date)) {
        return null;
      }
      // `parse` will always parse the date from the string in the users local timezone.
      // So we need to create the calendar date from local time. Otherwise the calendar
      // date could be on the wrong date.
      return CalendarDate.fromDate(date, getLocalTimezone());
    } catch (e) {
      return null;
    }
  }

  /**
   * Creates a CalendarDate from another date-like value
   */
  static fromCalendarDate(initialValue: DateString | CalendarDate | CalendarDateObject): CalendarDate {
    if (isCalendarDate(initialValue)) {
      return initialValue;
    }

    if (isCalendarDateObject(initialValue)) {
      return new CalendarDate(calendarDateObjectToDateString(initialValue));
    }

    if (isDateString(initialValue)) {
      return new CalendarDate(initialValue);
    }

    throw new Error(
      // eslint-disable-next-line max-len
      'Invalid date passed to CalendarDate constructor. Must be a CalendarDate, CalendarDateObject, or DateString',
    );
  }

  /**
   * Creates a CalendarDate from a native date type, either a Date or ISO string. The timezone is required.
   */
  static fromDate(initialValue: Date | ISODateString, timezone = 'UTC'): CalendarDate {
    if (isDate(initialValue)) {
      if (!timezone) {
        throw new Error('Date passed to CalendarDate constructor without the timezone option');
      }
      return new CalendarDate(dateToDateString(initialValue, timezone));
    }

    if (isISOString(initialValue)) {
      if (!timezone) {
        throw new Error('ISODateString passed to CalendarDate constructor without the timezone option');
      }
      return new CalendarDate(dateToDateString(new Date(initialValue), timezone));
    }

    throw new Error(
      // eslint-disable-next-line max-len
      'Invalid date passed to CalendarDate constructor. Must be a Date or ISODateString',
    );
  }

  /**
   * When parsing dates from the API, often the date is optional. This means that the developer needs to write
   * a guard for undefined every time. This method will do the same as fromDate but it will handle undefined.
   * @param initialValue
   * @param timezone
   * @returns
   */
  static fromDateOrUndefined(
    initialValue: Date | ISODateString | undefined,
    timezone?: string,
  ): CalendarDate | undefined {
    if (typeof initialValue === 'undefined' || initialValue === null) {
      return undefined;
    }
    return CalendarDate.fromDate(initialValue, timezone);
  }

  /**
   * Create a CalendarDate for today, relative to the timezone of the client.
   * @returns CalendarDate
   */
  static today(): CalendarDate {
    return CalendarDate.fromDate(new Date(), getLocalTimezone());
  }

  constructor(value: DateString) {
    if (!isDateString(value)) {
      throw new Error(
        'Invalid date passed to CalendarDate constructor. Must be a DateString in the format yyyy-mm-dd.',
      );
    }
    this.calendarDateValue = value;
  }

  /**
   * Get the start of the week
   */
  startOfWeek(): CalendarDate {
    const date = this.toDate(UTC_TIMEZONE);
    const dayIndex = date.getUTCDay();
    if (dayIndex === 0) {
      return new CalendarDate(this.calendarDateValue);
    }
    return this.subtractDays(dayIndex);
  }

  /**
   * Subtract days and return a new CalendarDate.
   */
  subtractDays(num: number): CalendarDate {
    const date = this.toDate(UTC_TIMEZONE);
    const newDate = subHours(date, num * 24);
    return CalendarDate.fromDate(newDate, UTC_TIMEZONE);
  }

  /**
   * Add days and return a new CalendarDate
   */
  addDays(num: number): CalendarDate {
    const date = this.toDate(UTC_TIMEZONE);
    const newDate = addHours(date, num * 24);
    return CalendarDate.fromDate(newDate, UTC_TIMEZONE);
  }

  /**
   * Add months and return a new CalendarDate
   */
  addMonths(num: number): CalendarDate {
    const currentMonth = this.getMonth();
    const currentYear = this.getYear();
    const currentDay = this.getDate();

    const targetMonth = currentMonth + num;

    const targetMonthIndex = targetMonth - 1; // Date months are zero indexed

    const newDate = new Date(Date.UTC(currentYear, targetMonthIndex, currentDay));

    return CalendarDate.fromDate(newDate, UTC_TIMEZONE);
  }

  /**
   * Get the year as a number
   */
  getYear(): number {
    const date = this.toDate(UTC_TIMEZONE);
    return date.getUTCFullYear();
  }

  /**
   * Get the year shortened (YY) as a number
   */
  getShortenedYear(): number {
    const date = this.toDate(UTC_TIMEZONE);
    const year = date.getUTCFullYear();
    return year % 100;
  }

  /**
   * Get the month as a number. Not the zero index.
   */
  getMonth(): number {
    const date = this.toDate(UTC_TIMEZONE);
    return date.getUTCMonth() + 1;
  }

  /**
   * Get the day of the month as a number.
   */
  getDate(): number {
    const date = this.toDate(UTC_TIMEZONE);
    return date.getUTCDate();
  }

  /**
   * Get the day of the week as a number
   */
  getDayOfWeek(): number {
    const date = this.toDate(UTC_TIMEZONE);
    return date.getUTCDay();
  }

  /**
   * Does this calendar date fall on a weekend?
   */
  isWeekend(): boolean {
    const dayOfWeek = this.getDayOfWeek();
    return dayOfWeek === 0 || dayOfWeek === 6;
  }

  /**
   * Check the month using the index.
   */
  isMonth(num: number): boolean {
    return this.getMonth() === num;
  }

  /**
   * Are two CalendarDates the same month?
   */
  isSameMonth(calendarDate: CalendarDate): boolean {
    return calendarDate.getMonth() === this.getMonth() && calendarDate.getYear() === this.getYear();
  }

  /**
   * Are two calendar dates the same date?
   */
  isSameDate(calendarDate: CalendarDate): boolean {
    return this.toString() === calendarDate.toString();
  }

  /**
   * Is this calendar date before the date you pass in?
   */
  isBefore(calendarDate: CalendarDate): boolean {
    return isBefore(this.toDate(UTC_TIMEZONE), calendarDate.toDate(UTC_TIMEZONE));
  }

  /**
   * Is this calendar date after the date you pass in?
   */
  isAfter(calendarDate: CalendarDate): boolean {
    return isAfter(this.toDate(UTC_TIMEZONE), calendarDate.toDate(UTC_TIMEZONE));
  }

  /**
   * Compares this calendar date to another calendar date.
   * Returns 0 if they are the same,
   * Returns 1 if this calendar date is after the other calendar date,
   * Returns -1 if this calendar date is before the other calendar date.
   */
  compare(calendarDate: CalendarDate): number {
    if (this.isSameDate(calendarDate)) {
      return 0;
    }
    if (this.isAfter(calendarDate)) {
      return 1;
    }
    return -1;
  }

  /**
   * Get the raw DateString value.
   */
  toString(): DateString {
    return this.calendarDateValue;
  }

  /**
   * Turn the calendar date into a Date. It requires a timezone because Dates always
   * include the time.
   */
  toDate(timezone: string): Date {
    return toDate(this.calendarDateValue, {
      timeZone: timezone,
    });
  }

  /**
   * Format the calendar date using the standard formatting options. These will be localised
   * using the Intl API.
   */
  format(options?: Pick<FormatOptions, 'locale' | 'style'>): string {
    const date = this.toDate(UTC_TIMEZONE);
    return formatDateWithStyle(date, {
      ...(options || { style: DateFormatStyle.STANDARD }),
      includeTime: false, // Time is never applicable for calendar dates.
      timezone: UTC_TIMEZONE, // Calendar dates always use UTC
    });
  }

  /**
   * Format a date string using a custom format string.
   */
  inputFormat(format: DateInputFormat): string {
    const date = this.toDate(UTC_TIMEZONE);
    return formatInTimeZone(date, UTC_TIMEZONE, format);
  }

  /**
   * Check if this calendar date is within the range of dates.
   */
  check(options?: CalendarDateValidationOptions): boolean {
    const { minDate, maxDate, allowWeekends = true } = options || {};

    if (minDate) {
      const valid = this.isAfter(minDate) || this.isSameDate(minDate);
      if (!valid) return false;
    }

    if (maxDate) {
      const valid = this.isBefore(maxDate) || this.isSameDate(maxDate);
      if (!valid) return false;
    }

    if (!allowWeekends && this.isWeekend()) {
      return false;
    }

    return true;
  }
}
