import classnames from 'classnames';
import type { RefObject } from 'react';
import { useLayoutEffect } from 'react';
import * as React from 'react';
import { createPortal } from 'react-dom';
import styled, { keyframes } from 'styled-components';

import { useModalPortalContainer } from './context';
import { useOnClickOutside } from '../../hooks/click-outside';
import { useFocusTrapKeyHandler } from '../../hooks/use-focus-trap-keyhandler';
import { zIndices } from '../../theme';

export interface ModalProps {
  bottom?: string;
  children: React.ReactNode;
  hideOnClickOutside?: boolean;
  onClickOutside?: () => void;
  innerRef?: RefObject<HTMLDivElement>;
  isOpen: boolean;
  maxWidth?: string;
  minWidth?: string;
  onToggle: (isOpen: boolean) => unknown;
  overlay?: boolean;
  overlayBgColor?: 'default' | 'black';
  overlayOpacity?: number;
  testId?: string;
  top?: string;
  width?: string;
  zIndex?: number;
  disableCloseOnEscape?: boolean;
  ariaLabel?: string;
}

export function Modal(props: ModalProps): JSX.Element | null {
  const {
    bottom,
    children,
    hideOnClickOutside = false,
    onClickOutside,
    disableCloseOnEscape = false,
    innerRef,
    isOpen,
    maxWidth = 'auto',
    minWidth = 'auto',
    onToggle,
    overlay = true,
    overlayBgColor = 'default',
    overlayOpacity = 0.57,
    testId,
    top = 64,
    width = 600,
    zIndex,
    ariaLabel,
  } = props;
  const modalContainer = useModalPortalContainer();
  const modalRef = React.useRef<HTMLDivElement>(null);

  useLayoutEffect((): (() => void) => {
    if (!isOpen) return () => null;
    const handler = (e: KeyboardEvent): void => {
      if (e.key === 'Escape' && !disableCloseOnEscape) {
        onToggle(false);
        // call below only if the event is handled, otherwise no other key listeners will be called
        e.stopImmediatePropagation(); // Prevents events from being propogated to parent modals
      }
    };
    document.body.addEventListener('keydown', handler);
    return (): void => document.body.removeEventListener('keydown', handler);
  }, [onToggle]);

  useFocusTrapKeyHandler({ containerRef: modalRef, visible: isOpen });

  useLayoutEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  const overlayClasses = classnames({
    overlay: true,
    show: overlay,
  });

  useOnClickOutside(innerRef as RefObject<HTMLElement>, (): void => {
    if (!isOpen || !hideOnClickOutside) {
      return;
    }

    if (onClickOutside) {
      onClickOutside();
      return;
    }

    onToggle(false);
  });

  if (!isOpen) {
    return null;
  }

  return createPortal(
    <StyledOverlay
      overlayBgColor={overlayBgColor}
      overlayOpacity={overlayOpacity}
      className={overlayClasses}
      data-testid={testId}
      ref={modalRef}
      zIndex={zIndex}
    >
      <StyledModal
        ref={innerRef}
        className="ui-modal"
        role="dialog"
        aria-modal
        aria-label={ariaLabel}
        style={{ width, minWidth, maxWidth, marginTop: top, marginBottom: bottom || '64px' }}
      >
        {children}
      </StyledModal>
    </StyledOverlay>,
    // if ModalPortalProvider was provided, render inside it and not to <body>
    modalContainer?.current || document.body,
  );
}

const overlayAnimation = (overlayOpacity: number | undefined, overlayBgColor: string | undefined) =>
  keyframes`
  from {
    background: ${overlayBgColor === 'default' ? 'rgba(31, 21, 41, 0)' : 'rgba(16, 22, 43, 0)'};
  }
  to {
    background: ${
      overlayBgColor === 'default' ? `rgba(31, 21, 41, ${overlayOpacity})` : `rgba(16, 22, 43, ${overlayOpacity})`
    };
  }
`;

const modalAnimation = keyframes`
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const StyledOverlay = styled.div<{ overlayOpacity?: number; overlayBgColor?: 'default' | 'black'; zIndex?: number }>`
  background: transparent;
  z-index: ${({ zIndex }) => zIndex ?? zIndices.overlay};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  &.show {
    animation: ${({ overlayOpacity, overlayBgColor }) => overlayAnimation(overlayOpacity, overlayBgColor)} 99ms ease-in;
    animation-fill-mode: both;
    background: ${({ overlayOpacity, overlayBgColor }) =>
      overlayBgColor === 'default' ? `rgba(31, 21, 41, ${overlayOpacity})` : `rgba(16, 22, 43, ${overlayOpacity})`};
  }
`;

const StyledModal = styled.div`
  animation: ${modalAnimation} 300ms cubic-bezier(0.16, 0.68, 0.165, 1.3);
  position: relative;
  margin-left: auto;
  margin-right: auto;
  z-index: ${zIndices.modal};
  &:focus {
    outline: none;
  }
`;
