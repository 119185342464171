/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react/jsx-props-no-spreading */
import type { SwitchWithLabelProps } from '@newfront-insurance/core-ui';
import { SwitchWithLabel as BaseSwitchWithLabel } from '@newfront-insurance/core-ui';
import { useAnalytics } from '@newfront-insurance/data-layer-client';

import type { TrackConfig } from '../../lib/types';

interface TrackEventsConfig {
  trackOnClick?: TrackConfig;
}
export type AnalyticSwitchWithLabelProps = SwitchWithLabelProps & TrackEventsConfig;

export function SwitchWithLabel(props: AnalyticSwitchWithLabelProps): JSX.Element {
  const analytics = useAnalytics();
  // we could maybe add a check and a warning if analytics is not defined here
  const { trackOnClick, onClick } = props;
  return (
    <BaseSwitchWithLabel
      {...props}
      onClick={(e) => {
        if (trackOnClick) {
          const { eventName, eventProperties } = trackOnClick;
          analytics.track({
            event: eventName,
            properties: typeof eventProperties === 'function' ? eventProperties() : eventProperties,
          });
        }
        onClick(e);
      }}
    />
  );
}
