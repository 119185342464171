import type { TRPCLink } from '@trpc/client';
import { TRPCClientError } from '@trpc/client';
import type { AnyRouter } from '@trpc/server';

interface ErrorLinkOptions {
  onError?: (error: TRPCClientError<AnyRouter>) => void;
}

/**
 * Logger middleware for @trpc/client that will automatically log information
 * about every request. This can be used to debug the requests being made in your apps.
 */
export function errorLink(options: ErrorLinkOptions): TRPCLink<AnyRouter> {
  const { onError } = options;

  return (_runtime) => {
    return ({ next, op, prev }) => {
      next(op, (result) => {
        // Link is disabled, so bail
        if (!onError) {
          prev(result);

          return;
        }
        if (result instanceof TRPCClientError) {
          onError(result);
        }

        prev(result);
      });
    };
  };
}
