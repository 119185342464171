import type { ReactNode } from 'react';

import { ErrorBoundary } from './error-boundary';
import { ErrorOverlay } from './error-overlay';
import { isNextApiErrorLike } from '../next-api-error/error';

interface Props {
  children: ReactNode;
  onError?: (error: Error) => unknown;
}

/**
 * This will catch any NextApiError that is thrown (outside of event handlers) and render a nice error message to the
 * user. This should wrap your application.
 */
export function NextApiErrorBoundary(props: Props): JSX.Element {
  const { children, onError } = props;

  return (
    <ErrorBoundary onError={onError}>
      {(error) => {
        if (error && isNextApiErrorLike(error)) {
          return <ErrorOverlay error={error} />;
        }
        return children;
      }}
    </ErrorBoundary>
  );
}
