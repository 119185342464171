/* eslint react/jsx-props-no-spreading: 0 */
import * as React from 'react';
import styled from 'styled-components';

import { Chevron } from './icons';
import { generateTestId } from '../../../lib/test-helpers';
import type { SpacingValue } from '../../../theme';
import { colors } from '../../../theme/colors';
import type { AlignItems } from '../../flexbox';
import { Flexbox, FlexCell } from '../../flexbox';
import { Padding } from '../../padding';

interface ExpandableHeadProps {
  alignItems?: AlignItems;
  arrowPosition?: 'right-end' | 'left' | 'right';
  children: React.ReactNode;
  // eslint-disable-next-line
  getToggleProps?: any;
  hideChevron?: boolean;
  id?: string;
  isClickAnywhere?: boolean;
  isExpanded?: boolean;
  onCollapsedBackgroundColor?: string;
  onExpandedBackgroundColor?: string;
  onHoverBackgroundColor?: string;
  iconColor?: string;
  paddingX?: SpacingValue;
  paddingY?: SpacingValue;
  testId?: string;
  borderBottom?: number;
  expandableBodyId?: string;
  focusStyle?: { [key: string]: any };
}

export const ExpandableHead = (props: ExpandableHeadProps): JSX.Element => {
  const {
    id,
    getToggleProps,
    alignItems = 'flex-start',
    arrowPosition = 'right-end',
    children,
    hideChevron,
    isClickAnywhere = false,
    isExpanded,
    iconColor = colors.steel[200],
    onCollapsedBackgroundColor,
    onExpandedBackgroundColor,
    onHoverBackgroundColor,
    paddingX = 24,
    paddingY = 24,
    testId,
    borderBottom = 0,
    expandableBodyId,
    focusStyle,
  } = props;
  const headToggleProps = isClickAnywhere ? getToggleProps : undefined;
  const iconToggleProps = isClickAnywhere ? undefined : getToggleProps;
  const { onClick, id: toggleId } = getToggleProps;

  return (
    <StyledHead
      borderBottom={borderBottom}
      className={isExpanded ? 'expanded' : 'collapsed'}
      collapsedBackgroundColor={onCollapsedBackgroundColor}
      data-testid={testId}
      expandedBackgroundColor={onExpandedBackgroundColor}
      hoverBackgroundColor={onHoverBackgroundColor}
      isClickAnywhere={isClickAnywhere}
      {...headToggleProps}
      onKeyDown={(e) => {
        if (e.key === ' ' || e.key === 'Enter') {
          e.preventDefault();
          onClick();
          e.stopPropagation();
        }
      }}
      aria-controls={expandableBodyId}
      id={id ?? toggleId}
      focusStyle={focusStyle}
    >
      <Padding x={paddingX} y={paddingY} style={{ zIndex: 0 }}>
        <Flexbox
          alignItems={alignItems}
          justifyContent={arrowPosition === 'right' ? undefined : 'space-between'}
          flexDirection={arrowPosition === 'left' ? 'row-reverse' : 'row'}
        >
          <FlexCell flex={arrowPosition === 'right' ? undefined : 1}>{children}</FlexCell>
          {!hideChevron &&
            (!isClickAnywhere ? (
              <StyledIconButton
                type="button"
                position={arrowPosition}
                data-testid={generateTestId(testId, 'trigger')}
                iconColor={iconColor}
                {...iconToggleProps}
                aria-label={isExpanded ? 'collapse content' : 'expand content'}
                aria-expanded={isExpanded}
              >
                <Chevron />
              </StyledIconButton>
            ) : (
              <StyledIcon position={arrowPosition} iconColor={iconColor}>
                <Chevron />
              </StyledIcon>
            ))}
        </Flexbox>
      </Padding>
    </StyledHead>
  );
};

const StyledHead = styled.div<{
  collapsedBackgroundColor?: string;
  expandedBackgroundColor?: string;
  hoverBackgroundColor?: string;
  isClickAnywhere?: boolean;
  borderBottom?: number;
  focusStyle?: { [key: string]: any };
}>`
  border-bottom: ${({ borderBottom }) => borderBottom}px solid ${colors.border.default};
  outline: 0;
  cursor: ${({ isClickAnywhere }) => (isClickAnywhere ? 'pointer' : 'default')};
  position: relative;
  transition: background-color 0.2s ease;

  &.expanded {
    background-color: ${({ expandedBackgroundColor }) => expandedBackgroundColor || 'inherit'};
    transition: background-color 0.3s ease;
  }

  &:not(.expanded) {
    background-color: ${({ collapsedBackgroundColor }) => collapsedBackgroundColor || 'inherit'};
  }

  &:hover {
    background: ${({ isClickAnywhere, hoverBackgroundColor }) => {
      return isClickAnywhere ? `${hoverBackgroundColor || colors.steel[100]}` : 'inherit';
    }};
  }

  &:focus {
    ${({ focusStyle }) =>
      focusStyle &&
      Object.keys(focusStyle)
        .map((key: string) => `${key}: ${focusStyle[key]};`)
        .join('')}
  }
`;

const getIconStyle = ({
  position,
  iconColor,
}: {
  position: ExpandableHeadProps['arrowPosition'];
  iconColor?: string;
}) => `
  cursor: pointer;
  outline: none;
  transition: transform 0.3s ease;
  transform: rotate(180deg);
  border-radius: 100px;
  color: ${iconColor};
  border: 0;
  background: none;
  ${position === 'left' ? 'margin-right: 16px;' : ''}
  ${position === 'right-end' ? 'margin-left: 16px;' : ''}
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  .expanded & {
    transition: transform 0.3s ease;
    transform: rotate(0);
  }

  &:hover {
    background: ${colors.steel[100]};
  }

  &:disabled {
    color: ${colors.steel[100]};

    &:hover {
      background: none;
    }
  }

  & svg {
    display: block;
    margin-bottom: 2px;
  }
`;

const StyledIconButton = styled.button(getIconStyle);
const StyledIcon = styled.span(getIconStyle);
