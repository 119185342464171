/* eslint-disable react/jsx-props-no-spreading */
import type { AuthProviderContext } from '@newfront-insurance/next-auth';
import type { Provider } from '@newfront-insurance/react-provision';
import type { NextRouter } from 'next/router';
import { useRouter } from 'next/router';
import * as React from 'react';

// eslint-disable-next-line import/no-cycle
import { HeartbeatProvider } from './heartbeat';
import type { SegmentClient, SegmentClientOptions } from './internals/client';
import { SegmentClientProvider, useSegmentClientContext } from './internals/client-provider';
import { RouteTracker } from './internals/route-tracker';
import { UserTracker } from './internals/user-tracker';
import type { AnalyticsUser, Properties } from './types';

/**
 * These are the traits we want to exist on every tracked user.
 */
interface BaseTraits extends Properties {
  email: string;
  legacyId?: number;
  avatar?: string;
}

interface BaseProps<UserTraits> extends SegmentClientOptions {
  children: React.ReactNode;
  user?: AnalyticsUser<UserTraits>;
  apiKey: string;
  router?: NextRouter;
  appName: string;
  heartbeatsEnabled?: boolean;
  defaultPageProperties?: Properties;
  authProvider: Provider<AuthProviderContext>;
  flags?: Record<string, boolean>;
  enabled?: boolean;
}

interface PropsWithRouter<UserTraits> extends BaseProps<UserTraits> {
  router: NextRouter;
}

/**
 * Adds the Segment client provider, tracks Next.js route changes, and identifies the current user.
 */
export function AnalyticsProvider<UserTraits extends BaseTraits>(props: BaseProps<UserTraits>): JSX.Element {
  const { children, router } = props;

  if (router) {
    return (
      <AnalyticsProviderWithRouter {...props} router={router}>
        {children}
      </AnalyticsProviderWithRouter>
    );
  }

  return <LegacyAnalyticsProvider {...props}>{children}</LegacyAnalyticsProvider>;
}

/**
 * This is the real analytics provider, but it's wrapped for backwards compatibility.
 */
function AnalyticsProviderWithRouter<UserTraits extends BaseTraits>(props: PropsWithRouter<UserTraits>): JSX.Element {
  const {
    children,
    user,
    apiKey,
    router,
    appName,
    heartbeatsEnabled = false,
    defaultPageProperties,
    authProvider: _authProvider,
    flags,
    ...clientOptions
  } = props;

  return (
    <SegmentClientProvider apiKey={apiKey} clientOptions={clientOptions}>
      <UserTracker user={user}>
        <RouteTracker router={router} appName={appName} defaultPageProperties={defaultPageProperties}>
          <HeartbeatProvider appName={appName} enabled={heartbeatsEnabled} flags={flags}>
            {children as JSX.Element}
          </HeartbeatProvider>
        </RouteTracker>
      </UserTracker>
    </SegmentClientProvider>
  );
}

/**
 * Wrap the real analytics provider and grab the router the way we used to.
 */
function LegacyAnalyticsProvider<UserTraits extends BaseTraits>(props: BaseProps<UserTraits>): JSX.Element {
  const { children } = props;
  const router = useRouter();

  return (
    <AnalyticsProviderWithRouter {...props} router={router}>
      {children}
    </AnalyticsProviderWithRouter>
  );
}

/**
 * Access the Segment client directly.
 */
export function useAnalytics(): SegmentClient {
  const { client } = useSegmentClientContext();
  return client;
}
