import type { FunctionComponent } from 'react';
import { createContext, useContext } from 'react';
import * as React from 'react';

import { useNextApiSubscription } from './request-hooks';

export interface ProviderProps<T = unknown, Variables = unknown> {
  variables?: Variables;
  // eslint-disable-next-line react/no-unused-prop-types
  onSuccess?: (data: T, key: string) => void;
  children: React.ReactNode;
}

export type UrlResolver<Variables> = (params: Variables) => string;
export type UrlResolverOrString<Variables> = UrlResolver<Variables> | string;
export type ProviderHook<T> = () => T;

export type NextApiProvider<T, Variables> = [
  FunctionComponent<ProviderProps<T, Variables>>,
  ProviderHook<T>,
  React.Context<T>,
];

/**
 * The url passed in can be a string or a function that will be passed the URL query params. This lets us set the url
 * dynamically when the uuids might live in the browser url.
 */
function resolveUrl<Params>(url: UrlResolverOrString<Params>, params: Params): string {
  if (typeof url === 'string') {
    return url;
  }
  return url(params);
}

/**
 * Create a set of hooks and context helpers to load a page-level resource. As this resource loads it will update
 * the page title. If it fails to load it will show an error overlay with details about the error.
 */
export function createNextApiProvider<T = unknown, Variables extends object = object>(
  url: UrlResolverOrString<Variables>,
): NextApiProvider<T, Variables> {
  const Context = createContext<T | undefined>(undefined);

  /**
   * Get the current value of the provider
   */
  function useProviderValue(): T {
    const value = useContext(Context);
    if (!value) {
      throw new Error('Provider value is not available. Make sure you’ve set the provider');
    }
    return value;
  }

  /**
   * Call the Next API route and set the value on the context provider. This should be wrapped in a Suspense component
   * to render loading states and you should use an error boundary component to catch any errors thrown.
   */
  // eslint-disable-next-line react/function-component-definition
  const Provider: FunctionComponent<ProviderProps<T, Variables>> = (props) => {
    const { children, variables } = props;
    const { data } = useNextApiSubscription<T>(resolveUrl(url, variables || ({} as Variables)), {
      suspense: true,
    });

    return <Context.Provider value={data}>{children}</Context.Provider>;
  };

  return [Provider, useProviderValue, Context as React.Context<T>];
}
