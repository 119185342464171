// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function getCacheKey<TTuple extends [string, ...unknown[]]>([path, input]: TTuple, extras?: string) {
  const cacheKey = [path, input ?? null].filter((val) => val !== null);
  if (extras) {
    cacheKey.push(extras);
  }
  return cacheKey;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export function getArgs<TPathAndInput extends unknown[], TOptions>(pathAndInput: TPathAndInput, opts: TOptions) {
  const [path, input] = pathAndInput;
  return [path, input, opts] as const;
}
