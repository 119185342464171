/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import type { AuthProviderContext } from '@newfront-insurance/next-auth';
import type { Provider } from '@newfront-insurance/react-provision';
import { useProvider } from '@newfront-insurance/react-provision';
import { getEnvFromUrl } from '@newfront-insurance/shared-public-config';
import { useEffect } from 'react';

// eslint-disable-next-line no-process-env
const isRumEnabled = typeof window !== 'undefined' && process.env.NODE_ENV === 'production';

const ERRORS_TO_IGNORE = [
  'ResizeObserver',
  'Abort fetching component for route',
  'Abort route change by user',
  'Route did not complete loading',
  'Failed to lookup route',
  '[LaunchDarkly]',
  'Failed to load script',
  'Failed to fetch',
  'Network Error',
  'The user aborted a request',
  'Uncaught "Script error."',
];

function initializeDatadogLogsAndRUM(applicationId: string, clientToken: string, appName: string): void {
  const env = getEnvFromUrl();
  // initializes both logs and rum
  if (isRumEnabled) {
    datadogLogs.init({
      clientToken,
      site: 'datadoghq.com',
      service: appName,
      // eslint-disable-next-line no-process-env
      env,
      enableExperimentalFeatures: ['feature_flags'],
      // eslint-disable-next-line no-process-env
      version: process.env.NEXT_PUBLIC_COMMIT_SHA,
      sessionSampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
    });
    datadogLogs.logger.setLevel('info');

    datadogRum.init({
      applicationId,
      clientToken,
      site: 'datadoghq.com',
      service: appName,
      // eslint-disable-next-line no-process-env
      env,
      allowedTracingUrls: [window.location.origin],
      // eslint-disable-next-line no-process-env
      version: process.env.NEXT_PUBLIC_COMMIT_SHA,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      trackFrustrations: true,
      defaultPrivacyLevel: 'allow',
      beforeSend(event) {
        if (event.type === 'error') {
          if (ERRORS_TO_IGNORE.some((errorToIgnore) => event.error.message.includes(errorToIgnore))) {
            return false;
          }
        }
      },
    });

    datadogRum.startSessionReplayRecording();
  }
}

interface UserData {
  uuid: string;
  email: string;
  firstName: string;
  lastName: string;
}

function setDatadogSessionUser(user?: UserData): void {
  if (user) {
    const userData = {
      email: user.email,
      id: user.uuid,
      name: `${user.firstName} ${user.lastName}`,
    };
    // Example of how to log with datadog
    browserLogger.info('Setting datadog user', userData);
    datadogRum.setUser(userData);
    datadogLogs.setUser(userData);
  }
}

interface Props {
  applicationId: string;
  clientToken: string;
  appName: string;
  authProvider: Provider<AuthProviderContext>;
  children: JSX.Element;
}

export function DatadogRumWrapper({ appName, applicationId, clientToken, authProvider, children }: Props) {
  const { userDetails } = useProvider(authProvider);

  useEffect(() => {
    initializeDatadogLogsAndRUM(applicationId, clientToken, appName);
  }, [applicationId, clientToken, appName]);

  useEffect(() => {
    if (userDetails && isRumEnabled) {
      setDatadogSessionUser(userDetails);
    }
  }, [userDetails]);

  return children;
}

interface ViewWrapperProps {
  viewName?: string;
  service: string;
  children: JSX.Element;
}

/**
 * Used to apply a custom name and service to a view
 */
export function DataDogViewWrapper({ viewName, service, children }: ViewWrapperProps): JSX.Element {
  useEffect(() => {
    if (viewName) {
      datadogRum.startView({ name: viewName, service });
    } else {
      datadogRum.startView({ service });
    }
  }, [viewName, service]);
  return children;
}

// If we are not in prod, we want to log to the console instead of datadog
const browserLogger = isRumEnabled
  ? datadogLogs.createLogger('browser', {
      level: 'info',
      context: {
        env: getEnvFromUrl(),
      },
    })
  : console;

export { datadogRum, browserLogger };
