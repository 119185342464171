import { colors } from '@newfront-insurance/core-ui';

export function ErrorIcon(): JSX.Element {
  return (
    <svg height={32} width={32} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" style={{ display: 'block' }}>
      <g fill={colors.fire[500]}>
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
      </g>
    </svg>
  );
}
