import type { BaseFetcher, RequestOptions } from '@newfront-insurance/browser-fetcher';
import { Fetcher } from '@newfront-insurance/browser-fetcher';
import { createContext, useContext, useMemo } from 'react';
import * as React from 'react';

import { useNextApiBaseUrl } from './util';

export const NextApiConfigContext = createContext<NextApiSwrOptions | null>(null);

interface Props {
  children: React.ReactNode;
  mockFetcher?: BaseFetcher;
  authToken?: string | (() => Promise<string | undefined>);
}

interface NextApiSwrOptions {
  fetcher: BaseFetcher;
}

type FetchFn = <B, R>(options: RequestOptions<B>) => Promise<R | null>;

/**
 * Creates a fetcher instance with the Next API set as the base url.
 */
export function useNextApiFetch(): FetchFn {
  const { fetcher } = useNextApiConfig();

  async function fetch<B, R>(options: RequestOptions<B>): Promise<R | null> {
    const { data, error } = await fetcher.request<B, R>(options);
    if (error) {
      throw error;
    }
    return data;
  }

  return fetch;
}

/**
 * Creates a fetcher instance with the Next API set as the base url.
 */
export function useNextApiFetcher(): BaseFetcher {
  const { fetcher } = useNextApiConfig();
  return fetcher;
}

/**
 * Configure the Next API hooks.
 */
export function NextApiSwrProvider(props: Props): JSX.Element {
  const { children, mockFetcher, authToken } = props;
  const baseUrl = useNextApiBaseUrl();

  const fetcher = useMemo(() => {
    if (mockFetcher) {
      return mockFetcher;
    }
    return new Fetcher({
      baseUrl,
      token: authToken,
    });
  }, [baseUrl, mockFetcher, authToken]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <NextApiConfigContext.Provider value={{ fetcher }}>{children}</NextApiConfigContext.Provider>;
}

/**
 * Get the current Next API config object
 */
export function useNextApiConfig(): NextApiSwrOptions {
  const legacyConfig = useContext(NextApiConfigContext);

  if (legacyConfig) {
    return legacyConfig;
  }

  throw new Error('Missing NextApiSwrProvider');
}
