import type React from 'react';
import { useEffect } from 'react';

import { useSegmentClientContext } from './client-provider';
import type { AnalyticsUser, Properties } from '../types';

interface Props<UserTraits> {
  children: React.ReactNode;
  user?: AnalyticsUser<UserTraits>;
}

/**
 * Use the Segment provider to add the initial identify and page calls.
 */
export function UserTracker<UserTraits extends Properties>(props: Props<UserTraits>): JSX.Element {
  const { children, user } = props;

  const { client: analytics } = useSegmentClientContext();

  // Whenever the user changes call analytics.identify so that all
  // track and page calls will be associated with that user.
  useEffect(() => {
    if (user) {
      // We should only call identify if the user is logged in. For anonymous users
      // this should never be called. Segment will create an anonymous ID for the user
      // and store that in local storage.
      // Calling identify in analytics.js will automatically associate all future track
      // calls with this user. This is not have the node client works.
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      analytics.identify(user);
    }
  }, [analytics, user]);

  return children as JSX.Element;
}
