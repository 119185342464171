export class TimeoutError extends Error {
  public request: Request;

  constructor(request: Request) {
    super('Request timed out');
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = 'TimeoutError';
    this.request = request;
  }
}
