import type { TextSizes } from '@newfront-insurance/core-ui';
import { colors, Flexbox, IconCross, IconTick, Text } from '@newfront-insurance/core-ui';
import { useEffect } from 'react';

import { useNotifications } from '../context';
import type { Notification } from '../types';

interface Props {
  id: string;
  notification: Notification;
}

export function NotificationItem(props: Props): JSX.Element {
  const { id, notification } = props;
  const { title, duration = 6000, type } = notification;
  const notifications = useNotifications();
  let typeStyles;
  let fontSize: TextSizes;
  switch (type) {
    case 'success':
      typeStyles = {
        background: colors.grass[400],
      };
      fontSize = 'small';
      break;
    case 'error':
      typeStyles = {
        background: colors.fire[500],
      };
      fontSize = 'small';
      break;
    default:
      typeStyles = {
        background: '#042553',
      };
      fontSize = 'regular';
  }

  useEffect(() => {
    if (duration <= 0) return () => undefined;
    const timeout = setTimeout(() => notifications.remove(id), duration);
    return (): void => {
      clearTimeout(timeout);
    };
  }, [duration]);

  return (
    <div
      style={{
        minWidth: 300,
        maxWidth: 400,
        paddingRight: 40,
        boxShadow: '2px 2px 16px rgba(38, 45, 70, 0.15)',
        borderRadius: 4,
        marginBottom: 8,
        position: 'relative',
        padding: 16,
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'pre-line',
        ...typeStyles,
      }}
    >
      <Flexbox alignItems="center" gap={16} justifyContent="center" width="100%" testId="notification-box">
        {type === 'success' && <IconTick size={16} />}
        {type === 'error' && <IconCross color="#ffffff" size={16} />}
        <Text color="white" textAlign="center" size={fontSize} testId="notification-text">
          {title}
        </Text>
      </Flexbox>
    </div>
  );
}
