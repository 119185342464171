import type { NextApiErrorDto, NextApiErrorLike } from '@newfront-insurance/data-layer-http';
import { getStatusText } from 'http-status-codes';

import { isNumber, isObjectLike, isString } from '../../utils';

interface NextApiErrorOptions {
  url: string;
  method: string;
  statusCode: number;
  data?: Record<string, unknown>;
}

/**
 * An error from the Next API. We standardize the shape of errors that are thrown by the API by wrapping all of the
 * functions in an error middleware function.
 */
export class NextApiError extends Error implements NextApiErrorLike {
  url: string;

  method: string;

  data?: Record<string, unknown>;

  statusCode: number;

  statusText: string;

  isClientError: boolean;

  isServerError: boolean;

  isAuthenticationError: boolean;

  isAuthorizationError: boolean;

  isNotFoundError: boolean;

  constructor(options: NextApiErrorOptions) {
    const { method, statusCode, url, data } = options;
    const statusText = getStatusText(statusCode);
    super(statusText);
    this.url = url;
    this.method = method;
    this.data = data;
    this.name = this.constructor.name;
    this.statusCode = statusCode;
    this.statusText = statusText;
    this.isClientError = statusCode >= 400 && statusCode < 500;
    this.isServerError = statusCode >= 500;
    this.isAuthenticationError = statusCode === 401;
    this.isAuthorizationError = statusCode === 403;
    this.isNotFoundError = statusCode === 404;

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(statusText).stack;
    }
  }
}

/**
 * Is the error object a NextApiError?
 * @param error
 */
export function isNextApiError(error?: Error): error is NextApiError {
  if (!error) {
    return false;
  }
  return error instanceof NextApiError;
}

/**
 * Is the error object NextApiErrorLike?
 * @param error
 */
export function isNextApiErrorLike(error: Error): error is NextApiErrorLike {
  return isNextApiError(error) || (isObjectLike(error) && error.isClientError != null && error.isServerError != null);
}

/**
 * Is the response a Next API error?
 * @param data
 */
export function isNextApiErrorDtoLike(data?: unknown): data is NextApiErrorDto {
  if (!data) return false;
  return isObjectLike(data) && isNumber(data.statusCode) && Array.isArray(data.errors) && isString(data.statusText);
}
