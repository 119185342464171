import { Text, Stack, colors } from '@newfront-insurance/core-ui';
import styled from 'styled-components';

import type { ErrorMessageProps } from '../types';

const StyledPre = styled.pre`
  white-space: pre-wrap;
  word-break: break-word;
  background: ${colors.steel[50]};
  padding: 12px;
`;

const StyledContainer = styled.div`
  max-width: 550px;
  margin: 0 auto;
  padding: 80px 20px;
`;

/**
 * This component can be used to render a full page error message. It will fill the content area
 * of the page and centre the error message.
 */
export function ErrorMessage({ title, subtitle, icon, action, rawErrorMessage }: ErrorMessageProps): JSX.Element {
  return (
    <StyledContainer>
      <Stack direction="vertical" gap={24} alignItems="center" justifyItems="center">
        {icon}
        <Stack direction="vertical" gap={4} alignItems="center" justifyItems="center">
          <Text textAlign="center">
            <strong>{title}</strong>
          </Text>
          <Text textAlign="center">{subtitle}</Text>
        </Stack>
        {action}
        {rawErrorMessage && <StyledPre>{rawErrorMessage}</StyledPre>}
      </Stack>
    </StyledContainer>
  );
}
