import { NextApiError } from '@newfront-insurance/next-api-error';
import cookies from 'js-cookie';

import type { FetcherOptions, FetcherResponse, BaseFetcher, RequestOptions } from './types';

/**
 * The default fetcher for making requests to the Next API. This uses the bog-standard fetch method. This can be
 * replaced in tests with something that is a bit more testable, like Axios.
 */
export class Fetcher implements BaseFetcher {
  private readonly xsrfHeaderName: string;

  private readonly xsrfCookieName: string;

  private readonly token: string | (() => Promise<string | undefined>);

  private readonly baseUrl: string;

  constructor(options: FetcherOptions = {}) {
    this.baseUrl = options.baseUrl || '';
    this.xsrfHeaderName = options.xsrfHeaderName || 'X-XSRF-TOKEN';
    this.xsrfCookieName = options.xsrfCookieName || 'XSRF-TOKEN';
    this.token = options.token || '';
  }

  async request<Body, Response>(options: RequestOptions<Body>): Promise<FetcherResponse<Response>> {
    const { url, body, method } = options;
    const authHeaders = await this.getAuthHeaders();

    const res = await fetch(`${this.baseUrl}${url}`, {
      headers: {
        'Content-Type': 'application/json',
        ...authHeaders,
      },
      body: JSON.stringify(body),
      method,
    });

    const data = await res.json();

    const error = !res.ok
      ? new NextApiError({
          url,
          method,
          statusCode: res.status,
          data,
        })
      : null;

    if (error) {
      return { data: null, error };
    }

    return { data, error: null };
  }

  private async getAuthHeaders(): Promise<Record<string, string>> {
    const headers: Record<string, string> = {};
    const token = typeof this.token === 'function' ? await this.token() : this.token;

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const xsrfToken = cookies.get(this.xsrfCookieName);
    if (xsrfToken) {
      headers[this.xsrfHeaderName] = xsrfToken;
    }

    return headers;
  }
}

export const fetcher = new Fetcher();
