import { useRouter } from 'next/router';

/**
 * The prefix to every API url, e.g. /api
 * It will automatically include the router baseUrl
 */
export function useNextApiBaseUrl(): string {
  const router = useRouter();
  const basePath = router && router.basePath ? router.basePath : '';
  return basePath;
}
