/**
 * This is taken from the ky library. Unfortunately we can't use ky directly
 * for fetching because they only support ES modules, which are difficult to use
 * right now.
 */
export class HTTPError<ResponseBody> extends Error {
  public response: Response;

  public request: Request;

  public options: RequestInit;

  public responseBody: ResponseBody;

  constructor(response: Response, request: Request, responseBody: ResponseBody, options: RequestInit) {
    const code = response.status || response.status === 0 ? response.status : '';
    const title = response.statusText || '';
    const status = `${code} ${title}`.trim();
    const reason = status ? `status code ${status}` : 'an unknown error';

    super(`Request failed with ${reason}`);
    Object.setPrototypeOf(this, new.target.prototype);

    this.name = 'HTTPError';
    this.response = response;
    this.request = request;
    this.options = options;
    this.responseBody = responseBody;
  }
}
