import { useNotifications } from './context';

export interface NotifyHook {
  success: (title: string, duration?: number) => void;
  error: (title: string, duration?: number) => void;
}

export function useNotify(): NotifyHook {
  const notifications = useNotifications();
  return {
    success: (title: string, duration?: number) =>
      notifications.add({
        title,
        type: 'success',
        duration,
      }),
    error: (title: string, duration?: number) =>
      notifications.add({
        title,
        type: 'error',
        duration,
      }),
  };
}
