import { Card, Padding, Text, Flexbox, Spacing, PopoverAnimation, Button } from '@newfront-insurance/core-ui';
import type { ReactNode } from 'react';
import { useState } from 'react';
import styled from 'styled-components';

import { ErrorDetails } from './error-details';
import { ErrorIcon } from './error-icon';
import type { NextApiError } from '../../../next-api-error/error';

interface Props {
  title: string;
  body: ReactNode;
  error: NextApiError | Error;
}

/**
 * Render a large error that can be used when a page resource fails to load.
 * @param props
 */
export function ErrorCard(props: Props): JSX.Element {
  const { title, body, error } = props;
  const [showDetails, setShowDetails] = useState(false);

  return (
    <StyledErrorContainer>
      <PopoverAnimation isOpen direction="up">
        <Card>
          <Padding size={24}>
            <Flexbox justifyContent="center" alignItems="center" flexDirection="column" style={{ maxWidth: 500 }}>
              <ErrorIcon />
              <Spacing height={16} />
              <Text textAlign="center" color="dark" weight={600}>
                {title}
              </Text>
              <Spacing height={4} />
              <Text textAlign="center">{body}</Text>
              <Spacing height={24} />
              <Button size="secondary" onClick={() => setShowDetails(!showDetails)}>
                {showDetails ? 'Less information' : 'More information'}
              </Button>
              {showDetails ? (
                <>
                  <Spacing height={24} />
                  <ErrorDetails error={error} />
                </>
              ) : null}
            </Flexbox>
          </Padding>
        </Card>
      </PopoverAnimation>
    </StyledErrorContainer>
  );
}

const StyledErrorContainer = styled.div`
  width: 500px;
  margin: 100px auto;
`;
