import { useState, useMemo } from 'react';

import type { QueuedItem } from './queue';
import { createMutableQueue, createImmutableQueue } from './queue';

export interface QueueHook<T> {
  addById: (id: string, data: T) => void;
  add: (data: T) => void;
  remove: (id: string) => void;
  removeAll: () => void;
  entries: QueuedItem<T>[];
}

/**
 * This hook lets you create a notification queue that can be manipulated. It accepts a notification type that describes
 * the shape of every notification that is allowed to be emitted. This doesn't provide any UI for the notifications.
 * This is left up to the end user to implement.
 *
 * This means you'll need to create your own NotificationProvider to create the queue, render the notifications, and
 * provide the queue API to your components so they can add notifications.
 *
 *      interface Data {
 *        message: string;
 *      }
 *
 *      function MyNotificationProvider() {
 *        const queue = useQueue<Data>();
 *
 *        return (
 *          queue.list.map(item => {
 *            return <div>{item.message}</div>
 *          })
 *        )
 *      }
 */
export function useImmutableQueue<T>(): QueueHook<T> {
  const initialQueue = useMemo(() => createImmutableQueue<T>(), []);
  const [queue, setQueue] = useState(initialQueue);

  return {
    /**
     * Add a new notification to the queue. If the ID already exists it will updated.
     * @param id Unique string identifier for notification.
     * @param data
     */
    addById(id: string, data: T): void {
      setQueue((list) => list.addById(id, data));
    },

    /**
     * Add a new notification to the queue.
     * @param data
     */
    add(data: T): void {
      setQueue((list) => list.add(data));
    },

    /**
     * Remove a notification by ID
     * @param id Unique string identifier for a notification
     */
    remove(id: string): void {
      setQueue((list) => list.remove(id));
    },

    /**
     * Remove all notifications from the page.
     */
    removeAll(): void {
      setQueue((list) => list.removeAll());
    },

    /**
     * The current array of notifications.
     */
    entries: queue.entries,
  };
}

export function useMutableQueue<T>(): QueueHook<T> {
  return useMemo(() => createMutableQueue<T>(), []);
}
