/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react/jsx-props-no-spreading */
import type { SegmentedControlProps } from '@newfront-insurance/core-ui';
import { SegmentedControl as BaseSegmentedControl } from '@newfront-insurance/core-ui';
import { useAnalytics } from '@newfront-insurance/data-layer-client';

import type { TrackConfig } from '../../lib/types';

interface TrackEventsConfig {
  trackOnChange?: TrackConfig;
}

export type AnalyticSegmentedControlProps = SegmentedControlProps & TrackEventsConfig;

export function SegmentedControl(props: AnalyticSegmentedControlProps): JSX.Element {
  const { trackOnChange, onChange } = props;
  const analytics = useAnalytics();
  return (
    <BaseSegmentedControl
      {...props}
      onChange={(value) => {
        if (trackOnChange) {
          const { eventName, eventProperties } = trackOnChange;
          analytics.track({
            event: eventName,
            properties: typeof eventProperties === 'function' ? eventProperties(value) : eventProperties,
          });
        }
        onChange(value);
      }}
    />
  );
}
