import styled from 'styled-components';

import type { SpacingValue } from '../../theme';

const StyledSpacing = styled.div<Props>`
  height: ${(p) => (p.height ? `${p.height}px` : 'auto')};
  width: ${(p) => (p.width ? `${p.width}px` : 'auto')};
  flex: none;
`;

interface Props {
  height?: SpacingValue;
  width?: SpacingValue;
}

export function Spacing({ height, width }: Props): JSX.Element {
  return <StyledSpacing height={height} width={width} />;
}
