import { Text } from '@newfront-insurance/core-ui';
import type { NextApiErrorLike } from '@newfront-insurance/data-layer-http';

import { isNextApiErrorLike } from '../next-api-error/error';

/**
 * Get the page title based on the request status
 * @param response
 */
export function getErrorPageTitle(error: NextApiErrorLike | Error): string {
  if (isNextApiErrorLike(error)) {
    if (error.isNotFoundError) {
      return 'Not found';
    }
    if (error.isAuthorizationError) {
      return 'Not allowed';
    }
    if (error.isAuthenticationError) {
      return 'Not logged in';
    }
  }
  return 'Error';
}

/**
 * Get the text for the error message if the quoting plan fails to load.
 */
export function getDefaultErrorTitle(error: NextApiErrorLike): string {
  if (error.isAuthenticationError) {
    return 'Not logged in';
  }
  if (error.isAuthorizationError) {
    return 'Not authorized';
  }
  if (error.isNotFoundError) {
    return 'Not found';
  }
  return error.message;
}

/**
 * Get the content for the error.
 */
export function getDefaultErrorText(error: NextApiErrorLike): JSX.Element {
  if (error.isAuthenticationError) {
    return (
      <Text>
        You need to be logged in to view this page. <a href="/login">Login</a>
      </Text>
    );
  }
  if (error.isAuthorizationError) {
    return <Text>You are not authorized to view this page.</Text>;
  }
  if (error.isNotFoundError) {
    return <Text>This page could not be found. Check the URL and try again.</Text>;
  }
  return (
    <Text>
      There was a problem loading this page. Check the URL and try again. If the problem persists send an email to{' '}
      <a href="mailto:bugs@newfront.com">bugs@newfront.com</a>
    </Text>
  );
}
