import type { SpacingValue } from '@newfront-insurance/core-ui';
import { Portal, Padding } from '@newfront-insurance/core-ui';
import type { NextApiErrorLike } from '@newfront-insurance/data-layer-http';
import { usePageTitle } from '@newfront-insurance/react-hooks';
import * as React from 'react';
import styled from 'styled-components';

import { ErrorCard } from './components/error-card';
import { getErrorPageTitle, getDefaultErrorTitle, getDefaultErrorText } from '../utils';

interface Props {
  error: NextApiErrorLike;
  title?: string;
  body?: React.ReactNode;
  top?: SpacingValue;
}

/**
 * Render an overlay that takes over the page with details about a NextApiError
 */
export function ErrorOverlay(props: Props): JSX.Element {
  const { error, title, body, top = 56 } = props;

  usePageTitle(getErrorPageTitle(error));

  return (
    <Portal name="error">
      <StyledOverlay>
        <Padding top={top}>
          <ErrorCard
            title={title || getDefaultErrorTitle(error)}
            body={body || getDefaultErrorText(error)}
            error={error}
          />
        </Padding>
      </StyledOverlay>
    </Portal>
  );
}

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 10;
`;
