/**
 * Represents a queued item. The ID is used internally to reference every notification. The generic types is intended
 * to be used by the end user so they can have a custom notification shape.
 */
export type QueuedItem<Data> = {
  id: string;
  data: Data;
};

/**
 * This is the returned result from the hook
 */
export interface Queue<T> {
  addById: (id: string, data: T) => Queue<T>;
  add: (data: T) => Queue<T>;
  remove: (id: string) => Queue<T>;
  removeAll: () => Queue<T>;
  entries: QueuedItem<T>[];
}

/**
 * Create an immutable queue. Adding and removing items will return a new queue.
 */
export function createImmutableQueue<T>(entries: QueuedItem<T>[] = []): Queue<T> {
  return {
    add(data: T): Queue<T> {
      const copy = entries.slice();
      copy.push({
        id: String(Date.now()),
        data,
      });
      return createImmutableQueue(copy);
    },
    addById(id: string, data: T): Queue<T> {
      const matchIndex = entries.findIndex((n) => n.id === id);
      const copy = entries.slice();
      if (matchIndex > -1) {
        copy.splice(matchIndex, 1, {
          id,
          data,
        });
      } else {
        copy.push({
          id,
          data,
        });
      }
      return createImmutableQueue(copy);
    },
    remove(id: string): Queue<T> {
      return createImmutableQueue(entries.filter((n) => n.id !== id));
    },
    removeAll(): Queue<T> {
      return createImmutableQueue();
    },
    entries,
  };
}

/**
 * Create a mutable queue. Adding and removing items will return the same queue so you can
 * assert that changes we made.
 */
export function createMutableQueue<T>(initialValue: QueuedItem<T>[] = []): Queue<T> {
  const entries = initialValue;
  const updateEntries = (queue: Queue<T>): void => {
    entries.splice(0, entries.length);
    entries.push(...queue.entries);
  };

  return {
    addById(id: string, data: T): Queue<T> {
      const queue = createImmutableQueue(entries).addById(id, data);
      updateEntries(queue);
      return this;
    },
    add(data: T): Queue<T> {
      const queue = createImmutableQueue(entries).add(data);
      updateEntries(queue);
      return this;
    },
    remove(id: string): Queue<T> {
      const queue = createImmutableQueue(entries).remove(id);
      updateEntries(queue);
      return this;
    },
    removeAll(): Queue<T> {
      const queue = createImmutableQueue(entries).removeAll();
      updateEntries(queue);
      return this;
    },
    entries,
  };
}
