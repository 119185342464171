/* eslint-disable no-nested-ternary */
import kebabCase from 'lodash/kebabCase';
import type { ReactElement } from 'react';
import * as React from 'react';
import styled from 'styled-components';

import { generateTestId } from '../../lib/test-helpers';
import { colors as legacyColors } from '../../theme';
import { colors } from '../../theme/colors';
import { cn } from '../../v2';
import { Flexbox } from '../flexbox';
import type { HexColor, TextColors, TextWeights } from '../text';
import { Text } from '../text';
import { Tooltip } from '../tooltip';

interface Option {
  label?: string;
  value: string;
  icon?: React.ReactNode;
  testId?: string;
  tooltip?: string;
  disabled?: boolean;
}

type SegmentedControlVariant = 'regular' | 'thin';
export interface SegmentedControlProps {
  options: Option[];
  value?: string;
  onChange: (value: string) => unknown;
  fluid?: boolean;
  style?: React.CSSProperties;
  fontWeight?: TextWeights;
  activeColor?: TextColors | HexColor;
  inactiveColor?: TextColors | HexColor;
  variant?: SegmentedControlVariant;
  testId?: string;
  containerClassname?: string;
  isDark?: boolean;
}

export function SegmentedControl(props: SegmentedControlProps): JSX.Element {
  const {
    options,
    value,
    onChange,
    fluid = false,
    style = {},
    fontWeight = 600,
    activeColor = 'active',
    variant = 'regular',
    testId: propTestId,
    containerClassname,
    isDark = false,
  } = props;

  const { inactiveColor = variant === 'thin' ? colors.steel[400] : 'dark' } = props;

  const containerClassnames = cn(
    'border border-solid border-[#dbdbdb]',
    {
      fluid,
    },
    containerClassname,
  );

  const getColor = (isActive: boolean, isDisabled: boolean) => {
    return isDisabled ? '#587479' : isActive ? activeColor : inactiveColor;
  };

  const items = options.map((option) => {
    const isActive = value === option.value;
    const color = getColor(isActive, !!option.disabled);

    const className = cn('border-[#dbdbdb]', {
      isActive,
      fluid,
    });
    const testId = option.testId ?? generateTestId(kebabCase(option.value), 'segmented-option');
    const getLabel = (): JSX.Element => {
      if (!option.icon && !option.label) {
        // This shouldn't happen
        return <></>;
      }
      if (option.icon && !option.label) {
        return (
          <div>
            {React.cloneElement(option.icon as ReactElement, {
              // size: 24, // this is used to make the height of the button the same as our <Button>
              color,
            })}
          </div>
        );
      }

      if (!option.icon && option.label) {
        return (
          <Text color={color} weight={option.disabled ? 400 : fontWeight} textAlign="center">
            {option.label}
          </Text>
        );
      }

      // Icon and Label exist
      return (
        <div className="flex flex-row items-center justify-center gap-0.5">
          {React.cloneElement(option.icon as ReactElement, {
            color,
          })}
          <Text color={color} weight={option.disabled ? 400 : fontWeight} textAlign="center">
            {option.label}
          </Text>
        </div>
      );
    };

    const optionComponent = (
      <StyledOption
        key={option.value}
        data-testid={testId}
        className={className}
        onClick={() => (option.disabled ? undefined : onChange(option.value))}
        role="checkbox"
        aria-checked={isActive}
        tabIndex={0}
        onKeyDown={(e) => {
          if (option.disabled) return;
          if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            onChange(option.value);
          }
        }}
        variant={variant}
        hasIcon={!!option.icon}
        darkTheme={isDark}
        disabled={option.disabled}
      >
        {getLabel()}
      </StyledOption>
    );

    return option.tooltip ? (
      <Tooltip key={option.value} message={option.tooltip} positionTip="bottom" maxWidth={350} disableKeyboardFocus>
        {optionComponent}
      </Tooltip>
    ) : (
      optionComponent
    );
  });

  return (
    <StyledFlexbox className={containerClassnames} style={style} variant={variant} testId={propTestId} flexWrap="wrap">
      {items}
    </StyledFlexbox>
  );
}

const StyledFlexbox = styled(Flexbox)(
  ({ variant }: { variant: SegmentedControlVariant }) => `
  border-radius: ${variant === 'thin' ? 4 : 3}px;
  box-sizing: border-box;
  width: max-content;
  height: max-content;
  &.fluid {
    width: 100%;
  }
`,
);

const StyledOption = styled.div(
  ({
    variant,
    hasIcon,
    darkTheme,
    disabled = false,
  }: {
    variant: SegmentedControlVariant;
    hasIcon: boolean;
    darkTheme: boolean;
    disabled?: boolean;
  }) => `
  padding: ${hasIcon ? `7px 16px` : variant === 'thin' ? '0px 16px 1px 16px' : '8px 16px 9px'};
  ${!disabled ? 'cursor: pointer' : 'cursor: not-allowed'};
  display: flex;
  background: ${darkTheme ? colors.steel[500] : '#fff'};
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${darkTheme ? colors.steel[200] : colors.border[2]};

  :last-child {
    border-right: none;
  }

  &.fluid {
    flex: 1;
  }

  &.isActive {
    background-color: ${darkTheme ? '#0957C380' : legacyColors.primary_xxxl};
  }
`,
);
