/* eslint-disable @typescript-eslint/no-floating-promises */
import type { NextRouter } from 'next/router';
import type React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { useSegmentClientContext } from './client-provider';
import type { Properties } from '../types';

interface Props {
  children: React.ReactNode;
  router: NextRouter;
  appName?: string;
  defaultPageProperties?: Properties;
}

/**
 * Automatically track page calls when the Next.js router changes.
 */
export function RouteTracker(props: Props): JSX.Element {
  const { children, router, appName, defaultPageProperties = {} } = props;

  const { client: analytics } = useSegmentClientContext();
  const [hasInitialPageView, setHasInitialPageView] = useState(false);
  const { pathname, route, basePath, asPath } = router;
  const [hasRouteChanged, setRouteChanged] = useState(false);
  const routerIsReady = router.isReady;

  const pageProperties = useMemo(() => {
    return {
      pathname,
      asPath,
      route,
      basePath,
      appName,
      ...defaultPageProperties,
    };
  }, [pathname, asPath, route, basePath, appName, defaultPageProperties]);

  // We deliberately do not want this to trigger on route changes because we have
  // the events hooked up below which are more reliable.
  useEffect(() => {
    if (hasInitialPageView || !routerIsReady) return;
    setHasInitialPageView(true);
    analytics.page({
      properties: pageProperties,
    });
  }, [analytics, hasInitialPageView, pageProperties, routerIsReady]);

  // Call analytics.page whenever there is a page transition
  useEffect(() => {
    const onChange = (): void => {
      // Next.js <12.2 will fire the routeChangeComplete event on any page that
      // uses a URL param on the initial page load. It won't do this on pages
      // that DONT use a URL param.
      if (!hasInitialPageView) return;
      setRouteChanged(true);
    };

    router.events.on('routeChangeComplete', onChange);
    return () => {
      router.events.off('routeChangeComplete', onChange);
    };
  }, [router, hasInitialPageView]);

  const isOutboundLink = (url: string, isPrevented: boolean): boolean => {
    // Customize this logic based on your definition of outbound links
    // For example, you can check if the link starts with "http://" or "https://"
    // Not prevented links are links that are not prevented by the defaultPrevented are also considered outbound
    // because it's not a next js link
    return url.startsWith('http://') || url.startsWith('https://') || !isPrevented;
  };

  useEffect(() => {
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let { target } = event as any;

      if (target.tagName !== 'A') {
        // Traverse up the DOM tree until an anchor tag is found
        while (target && target.tagName !== 'A') {
          target = target.parentElement as HTMLAnchorElement;
        }
      }

      // Check if an anchor tag was found
      if (target) {
        const href = target.getAttribute('href');

        // Check if the link is an outbound link
        if (isOutboundLink(href ?? '', event.defaultPrevented)) {
          // Track the outbound link here
          analytics.page({
            name: 'outbound-link',
            properties: {
              href,
            },
          });
        }
      }
    };

    // Attach the click event listener to a parent container element
    document.addEventListener('click', handleClick as unknown as EventListener);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('click', handleClick as unknown as EventListener);
    };
  }, [analytics]);

  // This is a bit of a hack because the route change event is fired before the properties on
  // the router are updated. This allows us to avoid calling analytics.page twice.
  useEffect(() => {
    if (hasRouteChanged) {
      analytics.page({
        properties: pageProperties,
      });
      setRouteChanged(false);
    }
  }, [hasRouteChanged, pageProperties, analytics]);

  return children as JSX.Element;
}
