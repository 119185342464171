import { Button, colors, Stack, Text } from '@newfront-insurance/core-ui';
import styled from 'styled-components';

const StyledContainer = styled.div`
  max-width: 550px;
  margin: 0 auto;
  padding: 80px 20px;
`;

const SERVICE_DESK_HREF = 'https://newfront.atlassian.net/servicedesk/customer/portal/1';

/**
 * This component can be used to render a full page error message. It will fill the content area
 * of the page and centre the error message.
 */
export function SessionTimeoutErrorBoundary(): JSX.Element {
  return (
    <StyledContainer>
      <Stack direction="vertical" gap={24} alignItems="center" justifyItems="center">
        <IconNetworkLost />
        <Stack direction="vertical" gap={4} alignItems="center" justifyItems="center">
          <Text textAlign="center">
            <strong>Your session timed out</strong>
          </Text>
          <Text textAlign="center">This might have happened because you were idle for too long.</Text>
        </Stack>
        <Button type="button" onClick={() => window.location.reload()}>
          Reload the page
        </Button>
        <Text size="large">If you think this is a bug</Text>{' '}
        <Button size="secondary" href={SERVICE_DESK_HREF} target="_blank" style={{ textAlign: 'center' }}>
          Create a ticket
        </Button>
      </Stack>
    </StyledContainer>
  );
}

export function IconNetworkLost(): JSX.Element {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none">
      <path
        // eslint-disable-next-line max-len
        d="M16.22 22.022L13.022 25.22C13.0706 25.9988 13.4215 26.7277 13.9999 27.2515C14.5783 27.7752 15.3385 28.0523 16.1182 28.0236C16.898 27.9949 17.6357 27.6627 18.174 27.0979C18.7124 26.533 19.0088 25.7802 19 25C18.9984 24.2443 18.7108 23.5172 18.1951 22.9648C17.6794 22.4124 16.9738 22.0755 16.22 22.022V22.022Z"
        fill={colors.fire[500]}
      />
      <path
        // eslint-disable-next-line max-len
        d="M15.988 18.001L19.551 14.499C18.396 14.1698 17.201 14.0018 16 14C14.2921 13.9952 12.6003 14.3293 11.0224 14.9829C9.44457 15.6366 8.01211 16.5968 6.808 17.808L9.636 20.636C10.4684 19.7992 11.4582 19.1355 12.5485 18.6832C13.6387 18.231 14.8077 17.9991 15.988 18.001V18.001Z"
        fill={colors.fire[500]}
      />
      <path
        // eslint-disable-next-line max-len
        d="M19.525 18.718C20.5859 19.1693 21.5501 19.8205 22.365 20.636L25.193 17.807C24.387 17.0063 23.48 16.3142 22.495 15.748L19.525 18.718V18.718Z"
        fill={colors.fire[500]}
      />
      <path
        // eslint-disable-next-line max-len
        d="M16 10C18.354 10 20.633 10.474 22.737 11.367L25.774 8.382C22.7552 6.81288 19.4022 5.99573 16 6C13.2084 5.99224 10.443 6.53835 7.86395 7.60672C5.28487 8.67508 2.94336 10.2445 0.974998 12.224L3.803 15.052C5.40104 13.4453 7.30187 12.1715 9.39549 11.3043C11.4891 10.4372 13.7339 9.99383 16 10V10Z"
        fill={colors.fire[500]}
      />
      <path
        // eslint-disable-next-line max-len
        d="M25.433 12.809C26.4287 13.46 27.3549 14.2117 28.197 15.052L31.025 12.224C30.181 11.3893 29.2692 10.626 28.299 9.942L25.433 12.809V12.809Z"
        fill={colors.fire[500]}
      />
      <path d="M5.586 27L29 3.586L30.414 5L7 28.414L5.586 27Z" fill={colors.fire[500]} />
    </svg>
  );
}
