import { useProvider } from '@newfront-insurance/react-provision';

import { ResponsiveProvider } from '../providers/responsive';

interface IsMobileReturnType {
  isMobile: boolean;
}

export function useIsMobile(): IsMobileReturnType {
  const { isMobile } = useProvider(ResponsiveProvider);
  return { isMobile };
}
