import { useProvider } from '@newfront-insurance/react-provision';
import { useSuspenseQuery } from '@tanstack/react-query';
import { asyncWithLDProvider, useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import * as React from 'react';

import { AuthProvider } from './auth';

import { getConfig } from '@/config';

const { LAUNCH_DARKLY } = getConfig();

interface Props {
  children: React.ReactNode;
}

/**
 * Initialises LaunchDarkly and automatically updates the current LaunchDarkly user whenever the auth state changes. We don't
 * set the user here and we just assume they're anonymous. We'll use the provider below to keep the LaunchDarkly user and the Newfront
 * user in sync.
 */
export function FeatureFlagProvider({ children }: Props): JSX.Element | null {
  const { data: LDAsyncProvider } = useSuspenseQuery({
    queryKey: ['LaunchDarkly'],
    queryFn: () => {
      return asyncWithLDProvider({
        clientSideID: LAUNCH_DARKLY.PROJECTS.CLIENT_DASH,
        options: { bootstrap: 'localStorage' },
        reactOptions: { useCamelCaseFlagKeys: false },
      });
    },
    refetchInterval: 0,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
  if (!LDAsyncProvider) {
    return null;
  }
  return (
    <LDAsyncProvider>
      <FeatureFlagUserProvider>{children}</FeatureFlagUserProvider>
    </LDAsyncProvider>
  );
}

/**
 * Internal component that updates the user in the LaunchDarkly client whenever the auth state
 * changes. The feature flags will be reloaded whenever the user changes.
 */
function FeatureFlagUserProvider({ children }: Props): JSX.Element {
  const { userDetails } = useProvider(AuthProvider);
  const client = useLDClient();

  useEffect(() => {
    if (!client) return;

    if (userDetails) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      client.identify({
        email: userDetails.email,
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        key: userDetails.uuid,
        anonymous: false,
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      client.identify({ anonymous: true });
    }
  }, [userDetails, client]);

  return <>{children}</>;
}

export enum FeatureFlags {
  ACCOUNT_REGISTRY = 'account-registry',
  BENEFITS_PLAN_COVERAGE_ASSOCIATION = 'benefits-plan-coverage-association',
  BENEFITS_PLAN_COVERAGE_NAVIGATOR_REFACTOR = 'benefits-plan-coverage-navigator-refactor',
  EB_FILE_MANAGEMENT = 'eb-file-management',
  EMPLOYEE_PORTAL = 'employee-portal',
  PLAN_DOCUMENT_DOWNLOAD = 'plan-document-download',
  NEW_PASSWORD_FLOW_ACTIVE = 'new-password-flow-active',
  PLAN_DETAILS_MODAL_DESKTOP = 'plan-details-modal',
  INTERNATIONAL_PLANS = 'international-plans',
  STEWARDSHIP_REPORT = 'stewardship-report',
  CONTRACT_ASSESSMENT_ACCOUNTS = 'contract-assessment-accounts',
  CONTRACT_ASSESSMENT_DEV_TEAM = 'contract-assessment-dev-team',
  RISK_PULSE = 'risk-pulse',
  CERTIFICATES = 'certificates',
  INSURABLE_ITEMS = 'insurable-items',
  DARK_SIDEBAR = 'dark-sidebar',
  STEWARDSHIP_OFF_CYCLE = 'stewardship-off-cycle',
  BEAMER = 'beamer',
  COST_COMPONENTS = 'cost-components',
  CLIENT_DASHBOARD_V2 = 'client-dashboard-v2',
  OPTIONAL_STATE_TAXES_AND_FEES = 'optional-state-taxes-and-fees',
}
