import type { RefObject } from 'react';
import { useCallback, useEffect } from 'react';

type Handler = (e: MouseEvent | TouchEvent) => unknown;

const SCROLL_BAR_OFFSET = 20;

export function useOnClickOutside<Element extends HTMLElement>(
  // we should allow RefObjects or RefCallbacks
  ref: RefObject<Element> | Element | null,
  handler: Handler,
): void {
  const clickListener = useCallback(
    (event: MouseEvent | TouchEvent): void => {
      if (!ref) {
        return;
      }

      let el: RefObject<Element> | Element | null = ref;

      // if it is a RefObject, then take .current
      if ('current' in ref) {
        el = ref?.current;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!el || el.contains(event.target)) {
        return;
      }

      if ((event as MouseEvent).offsetX + SCROLL_BAR_OFFSET >= document.body.clientWidth) {
        return;
      }

      handler(event);
    },
    [ref, handler],
  );

  useEffect(() => {
    document.addEventListener('mousedown', clickListener);
    document.addEventListener('touchstart', clickListener);
    return () => {
      document.removeEventListener('mousedown', clickListener);
      document.removeEventListener('touchstart', clickListener);
    };
  }, [ref, clickListener]);
}
