/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react/jsx-props-no-spreading */
import type { ModalProps } from '@newfront-insurance/core-ui';
import { Modal as BaseModal } from '@newfront-insurance/core-ui';
import { useAnalytics } from '@newfront-insurance/data-layer-client';
import { useDeepCompareEffect } from 'react-use';

import type { TrackConfig } from '../../lib/types';

interface TrackEventsConfig {
  trackOnToggle?: TrackConfig;
  trackOnOpen?: TrackConfig;
}

export type AnalyticModalProps = ModalProps & TrackEventsConfig;

export function Modal(props: AnalyticModalProps): JSX.Element {
  const { trackOnToggle, trackOnOpen, onToggle, children, isOpen } = props;
  const analytics = useAnalytics();

  useDeepCompareEffect(() => {
    if (isOpen && trackOnOpen) {
      analytics.track({
        event: trackOnOpen.eventName,
        properties:
          typeof trackOnOpen.eventProperties === 'function'
            ? trackOnOpen.eventProperties()
            : trackOnOpen.eventProperties,
      });
    }
  }, [isOpen, analytics, trackOnOpen]);

  return (
    <BaseModal
      {...props}
      onToggle={(value) => {
        if (trackOnToggle) {
          const { eventName, eventProperties } = trackOnToggle;
          analytics.track({
            event: eventName,
            properties: typeof eventProperties === 'function' ? eventProperties(value) : eventProperties,
          });
        }
        onToggle(value);
      }}
    >
      {children}
    </BaseModal>
  );
}
