import { NextApiSwrProvider } from '@newfront-insurance/next-api-swr';
import { useProvider } from '@newfront-insurance/react-provision';
import type { ReactNode } from 'react';

import { AuthSwapProvider } from './auth';

interface Props {
  children: ReactNode;
}

export function AuthSwrProvider(props: Props): JSX.Element | null {
  const { children } = props;
  const { getSwappedAccessToken } = useProvider(AuthSwapProvider);

  return <NextApiSwrProvider authToken={getSwappedAccessToken}>{children}</NextApiSwrProvider>;
}
