import { createTRPCClient, useClientQueryHooks } from '@newfront-insurance/data-layer-client';
import type {
  AnyAuthProviderContext,
  AuthProviderContext,
  AuthSwapProviderContext,
} from '@newfront-insurance/next-auth';
import type { Provider } from '@newfront-insurance/react-provision';
import { createProvider, useProvider } from '@newfront-insurance/react-provision';

import type { SharedRouter } from '../trpc/router';

interface Options {
  authProvider: Provider<AuthProviderContext> | Provider<AuthSwapProviderContext>;
  basePath: string;
}

export const AdminTRPCProvider = createProvider((options: Options) => {
  const { authProvider, basePath } = options;
  const { getToken } = useProvider(authProvider as Provider<AnyAuthProviderContext>);

  const client = createTRPCClient<SharedRouter>({
    getToken,
    url: basePath,
    timeoutInMilliseconds: 1000 * 40, // 40 seconds
  });

  return useClientQueryHooks(client);
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export function useAdminTRPC() {
  return useProvider(AdminTRPCProvider);
}
