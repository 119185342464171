import * as React from 'react';
import type { StyledComponentProps } from 'styled-components';
import styled from 'styled-components';

import { colors, shadows } from '../../theme';
import { colors as newColors } from '../../theme/colors';

interface GenericObject {
  [key: string]: string;
}

const backgroundColors: GenericObject = {
  dark: colors.primary_xd,
  light: '#aaa',
  extraLight: colors.gray_xl,
  primary: colors.primary,
  transparent: 'transparent',
  white: '#fff',
};

export type BorderRadius = 0 | 3 | 4 | 6 | 10;
type BoxShadow = 'overlay' | 'card' | 'none';
type BackgroundColors = 'white' | 'light' | 'dark';

type BorderStyle =
  | 'none'
  | 'hidden'
  | 'dotted'
  | 'dashed'
  | 'solid'
  | 'double'
  | 'groove'
  | 'ridge'
  | 'inset'
  | 'outset';

interface Props {
  id?: string;
  testId?: string;
  backgroundColor?: BackgroundColors | string;
  border?: number;
  borderStyle?: BorderStyle;
  borderTop?: number;
  borderLeft?: number;
  borderRight?: number;
  borderBottom?: number;
  borderRadius?: BorderRadius;
  borderTopRadius?: BorderRadius;
  borderRightRadius?: BorderRadius;
  borderBottomRadius?: BorderRadius;
  borderLeftRadius?: BorderRadius;
  boxShadow?: BoxShadow;
  children: React.ReactNode;
  elementRef?: React.RefObject<HTMLElement>;
  style?: React.CSSProperties;
  width?: number | string;
  onClick?: () => unknown;
}

type StyledProps = StyledComponentProps<'div', object, object, never>;

export function Box(props: Props & StyledProps): JSX.Element {
  const {
    backgroundColor = 'transparent',
    border = 0,
    borderStyle = 'solid',
    borderTop = 0,
    borderLeft = 0,
    borderRight = 0,
    borderBottom = 0,
    borderRadius = 0,
    borderTopRadius = 0,
    borderBottomRadius = 0,
    borderRightRadius = 0,
    borderLeftRadius = 0,
    boxShadow = 'none',
    children,
    id,
    testId,
    elementRef,
    style,
    width,
    onClick,
    ...rest
  } = props;

  const boxBackgroundColor = backgroundColors[backgroundColor];
  const widthStyle = typeof width === 'string' ? width : `${width}px`;

  return (
    <StyledBox
      ref={elementRef as React.RefObject<HTMLDivElement>}
      style={style}
      id={id}
      data-testid={testId}
      backgroundColor={boxBackgroundColor || backgroundColor}
      border={border}
      borderStyle={borderStyle}
      borderTop={borderTop}
      borderLeft={borderLeft}
      borderRight={borderRight}
      borderBottom={borderBottom}
      borderRadius={borderRadius}
      borderTopRadius={borderTopRadius}
      borderBottomRadius={borderBottomRadius}
      borderRightRadius={borderRightRadius}
      borderLeftRadius={borderLeftRadius}
      boxShadow={boxShadow}
      width={widthStyle}
      onClick={onClick}
      // eslint-disable-next-line
      {...rest}
    >
      {children}
    </StyledBox>
  );
}

const StyledBox = styled.div`
  background-color: ${({ backgroundColor }: Props) => backgroundColor};
  border-color: ${newColors.steel[200]};
  border-style: ${({ borderStyle }: Props) => borderStyle};
  border-top-width: ${({ borderTop, border }: Props) => borderTop || border}px;
  border-left-width: ${({ borderLeft, border }: Props) => borderLeft || border}px;
  border-right-width: ${({ borderRight, border }: Props) => borderRight || border}px;
  border-bottom-width: ${({ borderBottom, border }: Props) => borderBottom || border}px;
  border-top-left-radius: ${({ borderTopRadius, borderLeftRadius, borderRadius }: Props) =>
    borderTopRadius || borderLeftRadius || borderRadius}px;
  border-top-right-radius: ${({ borderTopRadius, borderRightRadius, borderRadius }: Props) =>
    borderTopRadius || borderRightRadius || borderRadius}px;
  border-bottom-left-radius: ${({ borderBottomRadius, borderLeftRadius, borderRadius }: Props) =>
    borderBottomRadius || borderLeftRadius || borderRadius}px;
  border-bottom-right-radius: ${({ borderBottomRadius, borderRightRadius, borderRadius }: Props) =>
    borderBottomRadius || borderRightRadius || borderRadius}px;
  box-shadow: ${({ boxShadow }: Props) => shadows[boxShadow as keyof typeof shadows] || 'none'};
  width: ${({ width }: Props) => (width ? `${width}` : 'auto')};

  ${({ onClick }: Props) =>
    onClick &&
    `
    cursor: pointer;
    transition: all 0.2s ease-out;
    &:hover {
      transform: scale(1.005);
      border-color: ${newColors.brand[200]};
    }
    `}
`;
